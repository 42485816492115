import { GET_DATA_LOG_SUCCESS, GET_DATA_LOG_BY_DATE } from '../constants/actionTypes';

const initialState = {
	log: [],
	loading: true,
	error: ''
};

export default (dataLog = initialState, action) => {
	switch (action.type) {
		case GET_DATA_LOG_SUCCESS:
			return {
				...dataLog,
				loading: false,
				log: action.payload
			};
			case GET_DATA_LOG_BY_DATE:
				return {
					...dataLog,
					loading: false,
					log: action.payload
				};
		default:
			return dataLog;
	}
};
