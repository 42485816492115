import { GET_DATA_LOG_SUCCESS, GET_DATA_LOG_BY_DATE } from '../constants/actionTypes';
import * as api from '../api';

export const getListLog = (auth) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getListLog(auth);
		console.log(data);
		const dataLog = data.result;
		dispatch({ type: GET_DATA_LOG_SUCCESS, payload: dataLog });
	} catch (error) {
		console.log(error);
	}
};

export const getListLogByDate = (auth, startDate, endDate) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getListLogByDate(auth, startDate, endDate);
		console.log(data);
		const dataLog = data.result;
		dispatch({ type: GET_DATA_LOG_BY_DATE, payload: dataLog });
	} catch (error) {
		console.log(error);
	}
};
