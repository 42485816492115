import { GET_DATA_REPORT_SUCCESS, GET_DATA_REPORT_BY_DATE, GET_DATA_REPORT_BY_SITE, GET_DATA_REPORT_BY_SCANNER, GET_DATA_REPORT_BY_VIN } from '../constants/actionTypes';

const initialState = {
	report: [],
	loading: true,
	error: ''
};

export default (dataReport = initialState, action) => {
	switch (action.type) {
		case GET_DATA_REPORT_SUCCESS:
			return {
				...dataReport,
				loading: false,
				report: action.payload
			};
		case GET_DATA_REPORT_BY_DATE:
			return {
				...dataReport,
				loading: false,
				report: action.payload
			};
		case GET_DATA_REPORT_BY_SITE:
		return {
			...dataReport,
			loading: false,
			report: action.payload
		};
		case GET_DATA_REPORT_BY_SCANNER:
		return {
			...dataReport,
			loading: false,
			report: action.payload
		};
		case GET_DATA_REPORT_BY_VIN:
		return {
			...dataReport,
			loading: false,
			report: action.payload
		};
		default:
			return dataReport;
	}
};
