import { GET_DATA_REPORT_SUCCESS, GET_DATA_REPORT_BY_DATE, GET_DATA_REPORT_BY_SITE, GET_DATA_REPORT_BY_SCANNER, GET_DATA_REPORT_BY_VIN, GET_DOWNLOAD_REPORT_SUCCESS } from '../constants/actionTypes';
import * as api from '../api';
import download from 'downloadjs';

export const getListReport = (auth) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getListReport(auth);
		console.log(data);
		const dataReport = data.result;
		dispatch({ type: GET_DATA_REPORT_SUCCESS, payload: dataReport });
	} catch (error) {
		console.log(error);
	}
};

export const getReportByDate = (auth, startDate, endDate) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getReportByDate(auth, startDate, endDate);
		console.log(data);
		const dataReport = data.result;
		dispatch({ type: GET_DATA_REPORT_BY_DATE, payload: dataReport });
	} catch (error) {
		console.log(error);
	}
};

export const getReportBySite = (auth, site) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getReportBySite(auth, site);
		console.log(data);
		const dataReport = data.result;
		dispatch({ type: GET_DATA_REPORT_BY_SITE, payload: dataReport });
	} catch (error) {
		console.log(error);
	}
};

export const getReportByScanner = (auth, scanner) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getReportByScanner(auth, scanner);
		console.log(data);
		const dataReport = data.result;
		dispatch({ type: GET_DATA_REPORT_BY_SCANNER, payload: dataReport });
	} catch (error) {
		console.log(error);
	}
};

export const getReportByVin = (auth, vin) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getReportByVin(auth, vin);
		console.log(data);
		const dataReport = data.result;
		dispatch({ type: GET_DATA_REPORT_BY_VIN, payload: dataReport });
	} catch (error) {
		console.log(error);
	}
};

export const getDownloadReportByDate = (auth, user_id, start, end) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getDownloadReportByDate(auth, user_id, start, end);
		download(data, `report-${start}-${end}.PDF`);
		console.log(data);
	} catch (error) {
		console.log(error);
	}
};

export const getDownloadReport = (auth, user_id) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getDownloadReport(auth, user_id);
		download(data, 'Report.PDF');
		console.log(data);
	} catch (error) {
		console.log(error);
	}
};
