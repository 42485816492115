import { makeStyles } from  '@mui/styles';

export default makeStyles((theme) => ({
  box:{
    width:'100%',
    height:'421px',
    top:'150px',
    background:'#FFFFFF',
    boxShadow:'0px 0px 3px rgba(0, 0, 0, 0.14)',
    borderRadius: '5px',
  },
  boxForm:{
    width:'85%',
    height:'56px',
    boxSizing: 'borderBox',
    borderRadius: '3px',
    zIndex: 0,
  },
  select:{
    width:'25vw',
    height:'56px',
    left:'10px',
    top:'5px',
    border: '1px solid #C4C4C4',
    boxSizing: 'borderBox',
    borderRadius: '3px',
    zIndex: 0,
  },
  appbar:{
    background: '#37474F',
    width:'40vw',
    height: '36px',
  },
  btn:{
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius:'4px',
    width:'8vw',
    height:'45px',
    left:'25px',
    top: '-5px',
  },
  text:{
    width:'129px',
    height:'87px',
    fontFamily: 'Noto Sans sans-serif',
    fontWeight: 'bold',
    fontSize: '64px',
    lineHeight: '87px',
    letterSpacing: '0.15px',
    color: '#5B5B5B',
  },
  text2:{
    width:'32px',
    height:'33px',
    fontFamily:'Noto Sans sans-serif',
    fontWeight:'bold',
    fontSize:'24px',
    lineHeight:'33px',
    letterSpacing:'0.15px',
    color:'#5B5B5B',
  },
  table:{
    padding:'16px 14px',
  },
  tHead:{
    fontFamily:'Noto Sans sans-serif',
    fontStyle:'normal',
    fontWeight:'bold',
    fontSize:'12px',
    lineHeight:'24px',
    letterSpacing:'0.15px',
    color: 'rgba(69, 90, 100, 0.87)',
  },
  tBody:{
    fontFamily:'Noto Sans sans-serif',
    fontStyle:'normal',
    fontSize:'14px',
    fontWeight:'normal',
    lineHeight:'143%',
    letterSpacing:'0.15px',
    color: 'rgba(66, 66, 66, 0.87)',
  },
  boxContainer:{
    width: '40vw',
    height: '421px',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.14)',
    borderRadius: '5px',
    background:'#FFFFFF',
  },
  image:{
    left:'5.97%',
    right:'76.29%',
    top:'20.27%',
    bottom:'-3.42%',
  },
  text: {
    lineHeight:'28px',
    textAlign:'center',
    textTransform:'uppercase',
  },
  textBtn: {
    height: '32px',
    left:'6px',
    right: '8px',
    top: 'calc(50% - 32px/2)',
    fontFamily: 'Roboto',
    fontWeight:'500',
    fontSize:'14px',
    lineHeight:'16px',
    textAlign:'center',
    letterSpacing:'1.25px',
    textTransform: 'uppercase',
  },
  dataFormContainer: {
    position:'fixed',
    width: '100%',
    height: '100%',
    backgroundColor:'black',
    backgroundColor:'rgba(0,0,0,.75)',
    alignItems: 'center',
    justifyContent:'center',
    top: 0,
    left: 0,
    zIndex: 1,
    backdropFilter: 'blur(21px)',
  },
  btnInput: {
    justifyContent:'center',
    alignItems:'center',
    width:'199px',
    height:'66px',
    top:'-70px',
    borderRadius:'4px'
  },
  circleRed: {
    color:'#F84646',
    boxSizing: 'borderBox',
  },
  circleGreen: {
    color:'#59E517',
    boxSizing: 'borderBox',
  },
  tableText: {
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  notifFormContainer: {
    position:'fixed',
    width: '30%',
    height: '50%',
    alignItems: 'center',
    justifyContent:'center',
    top: 0,
    marginLeft: '68vw',
    zIndex: 1,
  },
  btn2: {
    width:'73px',
    height:'24px',
    left:'9%',
    top:'calc(50% - 24px/2 + 0.5px)',
    borderRadius: '4px',
  }
}));
