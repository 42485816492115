import { GET_DATA_ASSIGNMENT_SUCCESS, GET_ALL_ASSIGNMENT_SUCCESS } from '../constants/actionTypes';
import * as api from '../api';
import download from 'downloadjs';

export const getListAssignment = (auth) => async (dispatch) => {
	try {
		const { data } = await api.getListAssignment(auth);
		const dataAssignment = data.result;
		dispatch({ type: GET_DATA_ASSIGNMENT_SUCCESS, payload: dataAssignment });
	} catch (error) {
		console.log(error);
	}
};

export const getAllAssignment = (auth) => async (dispatch) => {
	try {
		const { data } = await api.getAllAssignment(auth);
		const dataVin = data.result.vin;
		const dataCat = data.result.cat;
		const dataCity = data.result.city;
		dispatch({ type: GET_ALL_ASSIGNMENT_SUCCESS, payload: { dataVin, dataCat, dataCity } });
	} catch (error) {
		console.log(error);
	}
};

export const getPrintAssignment = (auth, id) => async (dispatch) => {
	try {
		const { data } = await api.getPrintAssignment(auth, id);
		download(data, 'Assignment.PDF');
		console.log(data);
	} catch (error) {
		console.log(error);
	}
};
