import { AUTH, LOGOUT } from '../constants/actionTypes';
import Cookies from 'universal-cookie';
import { useCookies } from 'react-cookie';
const cookies = new Cookies();
const cookiez = new Cookies();
const cookiezz = new Cookies();

console.log(cookies);

const initialState = {
  data: null,
  loading: true,
  error: '',
}

export default (state = {authData : null}, action) =>
{
  switch(action.type){
    case AUTH:
    cookies.set('access_token', JSON.stringify(action?.data), {path: '/', maxAge: 21600});
    cookiez.set('user_id', JSON.stringify(action?.id), {path: '/', maxAge: 21600});
    cookiezz.set('role_id', JSON.stringify(action?.role), {path: '/', maxAge: 21600});
    
    return {...state,
            authData: action?.dataUser,
          };
    default:
      return state;
  }
}
