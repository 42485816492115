import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import FormJenisKendaraan from './FormJenisKendaraan';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getVehicleType, getListVehicle } from '../../../actions/vehicle';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
require('dotenv').config();
const theme = createTheme();

export default function DataForm({ dataForm, vin, spesifikasi, vehicleId, vit_Id }) {
	const [ vehVin, setVehVin ] = useState(vin);
	const [ vitId, setVitId ] = useState(vit_Id);
	const [ vehDesc, setVehDesc ] = useState(spesifikasi);
	const [ showdataForm, setShowDataForm ] = useState(false);
	const [ typeForm, setTypeForm ] = useState(false);
	const [ open, setOpen ] = useState(false);
	const dataKendaraan = useSelector((state) => state.dataKendaraan);
	const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));
	const [ dataValid, setDataValid ] = useState(true);

	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getVehicleType(auth));
	}, []);

	const handleShowformJenisKendaraan = () => setTypeForm((prevTypeForm) => !prevTypeForm);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (vitId !== '' && vehVin !== '' && vehDesc !== '') {
			const resp = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/update`,
				withCredentials: false,
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
					'Content-Type': 'application/json',
					Accept: '*/*',
					token: auth
				},
				data: {
					veh_id: vehicleId,
					veh_vin: vehVin,
					vit_id: vitId,
					veh_desc: vehDesc
				}
			});

			const json = await resp;
			console.log(json);

			if (json.data) {
				dispatch(getListVehicle(auth));
				setOpen(false);
				setDataValid(true);
			} else {
				console.log('error');
			}
		} else {
			setDataValid(false);
		}
	};

	const handleOpen = (id) => {
		if (id !== null) {
			setOpen(true);
		}
	};

	const handleClose = () => {
		setOpen(false);
		setDataValid(true);
	};

	return (
		<ThemeProvider theme={theme}>
			<EditIcon sx={{ color: '#2196F3' }} onClick={() => handleOpen(vehicleId)} />
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.vehicleFormContainer}
			>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<Paper className={classes.paper} sx={{ mt: '7vh' }} style={{ borderRadius: '10px' }}>
						<Box className={classes.box}>
							<Typography
								className={classes.text}
								style={{ fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '18px' }}
								sx={{ ml: '2%', mt: '1%' }}
								component="h3"
								variant="h8"
							>
								FORM KENDARAAN
							</Typography>
						</Box>
						<Container component="form" className={classes.form} noValidate sx={{ mt: 4 }}>
							{!dataValid ? (
								<div>
									<Alert severity="error" sx={{ mt: 3, mb: 2 }}>
										Lengkapi Semua Data (*)
									</Alert>
								</div>
							) : null}
							<Grid container spacing={2}>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										type="text"
										value={vehVin}
										name="nomorPlat"
										onChange={(e) => setVehVin(e.target.value)}
										label="Nomor Plat"
										autoComplete="nomorPlat"
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '80%' }}
										required
										select
										type="text"
										value={vitId}
										name="jenisKendaraan"
										onChange={(e) => setVitId(e.target.value)}
										label="Jenis Kendaraan"
										autoComplete="jenisKendaraan"
									>
										{dataKendaraan.vehicleType.map((type) => (
											<MenuItem key={type.vit_id} value={type.vit_id}>
												{type.vit_name}
											</MenuItem>
										))}
									</TextField>
									<FormJenisKendaraan />
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										type="text"
										value={vehDesc}
										multiline
										rows={8}
										name="spesifikasiKendaraan"
										onChange={(e) => setVehDesc(e.target.value)}
										id="jenisKelamin"
										label="Sepisifikasi Kendaraan"
										autoComplete="spesifikasiKendaraan"
									/>
								</Grid>
							</Grid>

							<Container style={{ padding: '20px' }}>
								{/* <Container > */}
								<Grid container style={{ display: 'flex', flexDirection: 'row' }}>
									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											fullWidth
											variant="outlined"
											color="error"
											sx={{
												justifyContent: 'center',
												alignItems: 'center',
												width: '100%',
												height: '45px',
												borderRadius: '4px'
											}}
											onClick={handleClose}
										>
											Batal
										</Button>
									</Grid>

									<Grid item xs={2} md={2} lg={2} />

									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											style={{
												backgroundColor: '#0C8D19',
												justifyContent: 'center',
												alignItems: 'center',
												padding: '6px 6px 6px 8px',
												width: '100%',
												height: '45px',
												borderRadius: '4px'
											}}
											onClick={handleSubmit}
										>
											Simpan
										</Button>
									</Grid>
								</Grid>
							</Container>
						</Container>
					</Paper>
				</Container>
			</Modal>
		</ThemeProvider>
	);
}
