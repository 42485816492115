import { makeStyles } from '@mui/styles';
import Image from '../../Image/Background.jpg';

export default makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${Image})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: 'no-repeat',
  },
  paper: {
    alignItems: 'center',
    padding: '25px',
    width: '21.05vw',
    height: '20px',
    },
    paper2: {
      width: '21vw',
      border: '1px solid #C4C4C4',
      marginLeft:'calc(50% - 10.5vw)',
      marginRight:'calc(50% - 10.5vw)',
      padding: '25px',
      alignItems: 'center',
    },
  login: {
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#024F17',
  },
  header: {
    width: '100%',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#37474F'
  },
  header2: {
    width: '100%',
    textAlign: 'center',
    color: '#37474F',
  },
  span0: {
    color: '#FF0000',
  },
  boxMainImage: {
    width:'9vw',
    height:'10vh',
    background:'#FFFFFF',
    borderRadius:'0px 0px 13.3271px 13.3271px',
    marginLeft:'auto',
    marginRight:'auto',
  },
}))
