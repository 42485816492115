import {
			GET_DATA_KENDARAAN_SUCCESS,
			GET_TIPE_KENDARAAN_SUCCESS,
			ADD_VEHICLE_SUCCESS,
			ADD_VEHICLE_TYPE_SUCCESS,
			DELETE_VEHICLE_SUCCESS,
			GET_WEIGH_TOTAL_SUCCESS,
			GET_TOTAL_HD_SUCCESS,
			GET_TOTAL_MC_SUCCESS,
			GET_TOTAL_MD_SUCCESS,
			GET_TOTAL_MEDIUM_SUCCESS,
			GET_TOP_TRUCK_SUCCESS,
			GET_TOP_MUATAN_SUCCESS,
		} from '../constants/actionTypes';

const initialState = {
	vehicle: [],
	vehicleType: [],
	totalHD:[],
	totalMC:[],
	totalMD:[],
	totalMedium:[],
	topTruck:[],
	topMuatan:[],
	dataTotalTimbang: [],
	dataHD: [],
	dataMD: [],
	dataMC: [],
	dataTOT: [],
	loading: true,
	error: ''
};

export default (dataKendaraan = initialState, action) => {
	switch (action.type) {
		case GET_DATA_KENDARAAN_SUCCESS:
			return {
				...dataKendaraan,
				loading: false,
				vehicle: action.payload
			};
		case GET_TIPE_KENDARAAN_SUCCESS:
			return {
				...dataKendaraan,
				loading: false,
				vehicleType: action.payload
			};
		case ADD_VEHICLE_SUCCESS:
      dataKendaraan['loading'] = false;
      let dataTemp = dataKendaraan['vehicle']
      dataTemp = [...dataTemp, action.payload];
      dataKendaraan['vehicle'] = dataTemp;
      return dataKendaraan;
		case ADD_VEHICLE_TYPE_SUCCESS:
     dataKendaraan['loading'] = false;
     let vehicleTemp = dataKendaraan['vehicleType']
     vehicleTemp = [...vehicleTemp, action.payload];
     dataKendaraan['vehicleType'] = vehicleTemp;
     return dataKendaraan;
		case DELETE_VEHICLE_SUCCESS:
				return {
					...dataKendaraan,
					loading: false,
					vehicle: action.payload
				};
		case GET_WEIGH_TOTAL_SUCCESS:
					return {
						...dataKendaraan,
						loading: false,
						dataTotalTimbang: action.payload,
					};
			case GET_TOTAL_HD_SUCCESS:
						return {
							...dataKendaraan,
							loading: false,
							totalHD: action.payload
						};
			case GET_TOTAL_MC_SUCCESS:
						return {
							...dataKendaraan,
							loading: false,
							totalMC: action.payload
					};
			case GET_TOTAL_MD_SUCCESS:
						return {
							...dataKendaraan,
							loading: false,
							totalMD: action.payload
						};
			case GET_TOTAL_MEDIUM_SUCCESS:
						return {
							...dataKendaraan,
							loading: false,
							totalMedium: action.payload
						};
			case GET_TOP_TRUCK_SUCCESS:
						return {
							...dataKendaraan,
							loading: false,
							topTruck: action.payload
						};
		case GET_TOP_MUATAN_SUCCESS:
					return {
						...dataKendaraan,
						loading: false,
						topMuatan: action.payload
					};
		default:
			return dataKendaraan;
	}
};
