import { makeStyles } from  '@mui/styles';

export default makeStyles((theme) => ({
  paper: {
    width:'30vw',
    // height:'562px',
    left:'537px',
    boxShadow:'0px 0px 3px rgba(0, 0, 0, 0.14)',
  },
  text: {
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color:'#FFFFFF',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: '#37474F',
    borderRadius: '7px 7px 0px 0px',
    height: '36px',
  },
  driverFormContainer: {
    // position:'fixed',
    // width: '100%',
    // height: '100%',
    // backgroundColor:'black',
    // backgroundColor:'rgba(0,0,0,.3)',
    // alignItems: 'center',
    // justifyContent:'center',
    // top: 0,
    // left: 0,
    // zIndex: 1,
    // backdropFilter: 'blur(21px)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.6)',
  },
}))
