import React, {useState, useEffect, useRef, useCallback, Item} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import moment from 'moment';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import makeStyles from './styles';
import { getListReport, getReportByDate, getReportBySite, getReportByScanner, getReportByVin, getDownloadReportByDate, getDownloadReport } from '../../actions/report';
import { getListSite } from '../../actions/site';
import { getListScanner } from '../../actions/scanner';
import { getListVehicle } from '../../actions/vehicle';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBar from "material-ui-search-bar";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'no',
    numeric: false,
    disablePadding: true,
    label: 'NO',
  },
  {
    id: 'loc_code',
    numeric: true,
    disablePadding: false,
    label: 'KODE SITE',
  },
  {
    id: 'timestamp',
    numeric: true,
    disablePadding: false,
    label: 'TIMESTAMP',
  },
  {
    id: 'veh_vin',
    disablePadding: false,
    label: 'NO LAMBUNG',
  },
  {
    id: 'uuid',
    disablePadding: false,
    label: 'KODE SCANNER (NO.SERI)',
  },
  {
    id: 'cat_code',
    disablePadding: false,
    label: 'JENIS',
  },
  {
    id: 'total',
    disablePadding: false,
    label: 'TOTAL',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
    const classes = makeStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.tableText}
              sx={{  color: '#5D7078'}}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = makeStyles();

  const { numSelected } = props;

  return (
    <Box
      sx={{
        backgroundColor:'#37474F',
        color:'#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        borderRadius: '5px 5px 0px 0px',
        padding:'3px',
				height:'36px'
      }}
    >
        <Typography
          sx={{ ml: 2 }}
          style={{ fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '18px' }}
          variant="h6"
          id="tableTitle"
          component="div"
          className={classes.title}
        >
          REPORT
        </Typography>
    </Box>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {

  const dispatch = useDispatch();
  const location = useLocation();
  const classes = makeStyles();

  const [order, setOrder] = useState('asc');
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState('loc_code');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [minDateStart, setMinDateStart] = useState(null);
  const [searched, setSearched] = useState("");
  const [site, setSite] = useState(null);
  const [scanner, setScanner] = useState(null);
  const [noLambung, setNoLambung] = useState(null);
  const dataReport = useSelector((state) => state.dataReport);
  const dataSite = useSelector((state) => state.dataSite);
  const dataScanner = useSelector((state) => state.dataScanner);
  const dataKendaraan = useSelector((state) => state.dataKendaraan);
  const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));
  const [ userId, setUserId ] = useState(decodeURI(cookies.get('user_id')));
  const [downloaded, setDownloaded] = useState(false);
  const [addDays, setAddDays] = useState(false);
  const timer = useRef();
  const [downloadProgress, setDownloadProgress] = useState(false);

  const startDate = new Date();

  const getData = useCallback(() => {
  if (dateStart == null && dateEnd == null && site == null && scanner == null && noLambung == null ){
        dispatch(getListReport(auth));
      }
  if (dateStart !== null && dateEnd !== null)
    {
      dispatch(getReportByDate(auth, dateStart, dateEnd));
    }
  if (site !== null)
    {
      dispatch(getReportBySite(auth, site));
    }
  if (scanner !== null)
    {
      dispatch(getReportByScanner(auth, scanner));
    }
    if (noLambung !== null)
    {
      dispatch(getReportByVin(auth, noLambung));
    }

  }, [ dateStart, dateEnd, site, scanner, noLambung, dispatch]);

  useEffect(() => {
    dispatch(getListSite(auth));
    dispatch(getListScanner(auth));
    dispatch(getListVehicle(auth));
    getData();
    console.log(site);
		console.log(dateStart);
    console.log(dateEnd);
	}, [getData]);

  timer.current = window.setTimeout(() => {
          setLoading(false);
      }, 1500);

  const handlePrint = (token, user_id, start, end) => {
    if(start !== null && end !== null)
    {
      dispatch(getDownloadReportByDate(token, user_id, start, end));
    }
    else {
      dispatch(getDownloadReport(token, user_id));
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataReport.report.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    console.log(rowsPerPage);
    setPage(0);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataReport.report.length) : 0;

  return (
    <Box>
      {/*filter dan icon diatas*/}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>

          {/* Filter Site */}
          <Grid item xs={6} lg={2}>
            <TextField
              sx={{width:'100%', backgroundColor: '#FFFFFF'}}
              select
              type="text"
              value={site}
              name="site"
              onChange={(e) => setSite(e.target.value)}
              id="site"
              label="Site"
              autoComplete="site"
            >
            {dataSite.site.map((data) => (
              <MenuItem key={data.loc_id} value={data.loc_id}>
                  {data.loc_code}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          {/* Filter Scanner */}
          <Grid item xs={6} lg={2}>
            <TextField
              sx={{width:'100%', backgroundColor: '#FFFFFF'}}
              select
              type="text"
              value={scanner}
              name="scanner"
              onChange={(e) => setScanner(e.target.value)}
              id="scanner"
              label="Scanner"
              autoComplete="scanner"
            >
              {dataScanner.scanner.map((data) => (
                <MenuItem key={data.device_id} value={data.device_id}>
                  {data.uuid}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Filter No Lambung */}
          <Grid item xs={6} lg={2}>
            <TextField
              sx={{width:'100%', backgroundColor: '#FFFFFF'}}
              select
              type="text"
              value={noLambung}
              name="noLambung"
              onChange={(e) => setNoLambung(e.target.value)}
              id="noLambung"
              label="No Lambung"
              autoComplete="noLambung"
            >
              {dataKendaraan.vehicle.map((data) => (
                <MenuItem key={data.veh_id} value={data.veh_id}>
                  {data.veh_vin}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Filter Date */}
          <Grid item xs={6} lg={4}>
            <Grid container spacing={2}>
              {/* Start Date */}
              <Grid item xs={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                  label="Start Date"
                  inputFormat="yyyy-MM-dd"
                  value={dateStart}
                  disableFuture
                  onChange={(newValue) => {
                        setDateStart(moment(newValue).format('yyyy-MM-DD'));
                        setMinDateStart(newValue);
                        }}
                  renderInput={(params) => <TextField {...params} sx={{bgcolor:'#FFFFFF',  width:'100%'}}/>}
                  />
                </LocalizationProvider>
              </Grid>
              {/* End Date */}
              <Grid item xs={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="yyyy-MM-dd"
                    value={dateEnd}
                    disableFuture
                    minDate={minDateStart}
                    onChange={(newValue) => {
                          setDateEnd(moment(newValue).format('yyyy-MM-DD'));
                          }}
                    renderInput={(params) => <TextField {...params} sx={{bgcolor:'#FFFFFF', width:'100%'}}/>}
                  />
                </LocalizationProvider>
              </Grid>

            </Grid>


          </Grid>

          {/* Filter Icon */}
          <Grid item xs={6} lg={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color='success'
                  onClick={() => handlePrint(auth, userId, dateStart, dateEnd)}
                  style={{
                    alignItems:'center',
                    // width:'54px',
                    height:'54px',
                    borderRadius:'4px',
                    padding: '6px 8px',
                    display: 'flex',
                    backgroundColor: '#0C8D19[500]',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: '#0C8D19[700]',
                    }
                  }}
                >
                  <PrintIcon/>
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Box>

      <br></br>
      {/*Table*/}
      <Grid item xs={12}>
        <Box sx={{ width:'100%' }}>
          <Paper sx={{ maxWidth: '100vw', mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size='small'
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={dataReport.report.length}
                />
                {
                  !dataReport.report.length?
                  <div>
                  { loading ?
                    <div>
                      <CircularProgress />
                    </div>
                    :
                    <Typography sx={{ml:2}} style={{fontFamily: 'Noto Sans', fontSize:'14px'}} > Data tidak ditemukan </Typography>
                  }
									</div>
                  :
                  <TableBody>
                    {stableSort(dataReport.report, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((report, index) => (
                            <TableRow>
                              <TableCell
                                style={{fontFamily: 'Noto Sans', fontSize:'14px'}}
                                component="th"
                              >
                                {(page*rowsPerPage)+index + 1}
                              </TableCell>
                              <TableCell style={{fontFamily: 'Noto Sans', fontSize:'14px'}}>{report.loc_code}</TableCell>
                              <TableCell style={{fontFamily: 'Noto Sans', fontSize:'14px'}}>{report.date}</TableCell>
                              <TableCell style={{fontFamily: 'Noto Sans', fontSize:'14px'}}>{report.veh_vin}</TableCell>
                              <TableCell style={{fontFamily: 'Noto Sans', fontSize:'14px'}}>{report.uuid}</TableCell>
                              <TableCell style={{fontFamily: 'Noto Sans', fontSize:'14px'}}>{report.cat_code}</TableCell>
                              <TableCell style={{fontFamily: 'Noto Sans', fontSize:'14px'}}>{report.total}KG</TableCell>
                            </TableRow>
                    ))}
                  </TableBody>
                }
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={dataReport.report.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Grid>



    </Box>

  );
}
