import { GET_DATA_JENIS_MUATAN_SUCCESS, ADD_CATEGORY_SUCCESS, DELETE_CATEGORY_SUCCESS } from '../constants/actionTypes';

const initialState = {
	category: [],
	loading: true,
	error: ''
};

export default (dataCategory = initialState, action) => {
	switch (action.type) {
		case GET_DATA_JENIS_MUATAN_SUCCESS:
			return {
				...dataCategory,
				loading: false,
				category: action.payload
			};
		case ADD_CATEGORY_SUCCESS:
		    dataCategory['loading'] = false;
		    let dataTemp = dataCategory['category']
		    dataTemp = [...dataTemp, action.payload];
		    dataCategory['category'] = dataTemp;
		   return dataCategory;
	case DELETE_CATEGORY_SUCCESS:
	     return { ...dataCategory,
	              loading: false,
	              category: action.payload
	     };
		default:
			return dataCategory;
	}
};
