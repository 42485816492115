import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CompassCalibrationIcon from '@mui/icons-material/CompassCalibration';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import GroupIcon from '@mui/icons-material/Group';
import TodayIcon from '@mui/icons-material/Today';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import StarBorder from '@mui/icons-material/StarBorder';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import makeStyles from './styles';
import Cookies from 'universal-cookie';
import { LOGOUT } from '../../constants/actionTypes';
const cookies = new Cookies();

const ListItems = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const [ selectedIndex, setSelectedIndex ] = useState(0);
	const [ cookie, removeCookie ] = useCookies([ 'access_token' ]);
	const [ open, setOpen ] = useState(false);
	const [ exit, setExit ] = useState(false);
	const classes = makeStyles();

	const handleClick = () => {
		setOpen(!open);
	};

	const handleLogout = () => {
		const cookies = new Cookies();
		const token = cookies.remove('access_token', { path: '/' });
		const id = cookies.remove('user_id', { path: '/' });
		navigate('/');
	};

	const handleListItemClick = (event, index, path) => {
		setSelectedIndex(index);
		navigate(path);
	};

	const handleClickOpen = () => {
		setExit(true);
	};

	const handleClose = () => {
		setExit(false);
		console.log(window.location);
	};

	const [ userId, user_id ] = useState(decodeURI(cookies.get('role_id')));
	// console.log('ikijing' + userId);

	return (
		<div>
			<ListItemButton
				selected={selectedIndex === 0}
				style={{ backgroundColor: selectedIndex === 0 ? '#283237' : '' }}
				onClick={(event) => handleListItemClick(event, 0, 'home')}
				sx={{ color: '#FFFFFF' }}
			>
				<ListItemIcon>
					<DashboardIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Dashboard" />
			</ListItemButton>
			<ListItemButton style={{ borderBottom: '1px solid #C4C4C4' }}>
				<ListItemIcon />
				<Typography style={{ fontWeight: 'bold', fontSize: '12px', color: '#C4C4C4' }}>MENU</Typography>
			</ListItemButton>
			<ListItemButton
				selected={selectedIndex === 1}
				style={{ backgroundColor: selectedIndex === 1 ? '#283237' : '' }}
				onClick={(event) => handleListItemClick(event, 1, 'driver')}
				sx={{ color: '#FFFFFF' }}
			>
				<ListItemIcon>
					<AssignmentIndIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Input Driver" />
			</ListItemButton>
			<ListItemButton
				selected={selectedIndex === 2}
				style={{ backgroundColor: selectedIndex === 2 ? '#283237' : '' }}
				onClick={(event) => {
					handleListItemClick(event, 2, 'carList');
				}}
				sx={{ color: '#FFFFFF' }}
			>
				<ListItemIcon>
					<LocalShippingIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Input Kendaraan" />
			</ListItemButton>
			<ListItemButton
				selected={selectedIndex === 3}
				style={{ backgroundColor: selectedIndex === 3 ? '#283237' : '' }}
				onClick={(event) => {
					handleListItemClick(event, 3, 'asalMuatan');
				}}
				sx={{ color: '#FFFFFF' }}
			>
				<ListItemIcon>
					<ShareLocationIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Input Asal Muatan" />
			</ListItemButton>
			<ListItemButton
				selected={selectedIndex === 4}
				style={{ backgroundColor: selectedIndex === 4 ? '#283237' : '' }}
				onClick={(event) => {
					handleListItemClick(event, 4, 'JenisMuatan');
				}}
				sx={{ color: '#FFFFFF' }}
			>
				<ListItemIcon>
					<MonitorWeightIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Input Jenis Muatan" />
			</ListItemButton>
			<ListItemButton
				selected={selectedIndex === 5}
				style={{ backgroundColor: selectedIndex === 5 ? '#283237' : '' }}
				onClick={(event) => {
					handleListItemClick(event, 5, 'siteJembatan');
				}}
				sx={{ color: '#FFFFFF' }}
			>
				<ListItemIcon>
					<CalendarViewDayIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Site Jembatan" />
			</ListItemButton>
			<ListItemButton
				selected={selectedIndex === 6}
				style={{ backgroundColor: selectedIndex === 6 ? '#283237' : '' }}
				onClick={(event) => {
					handleListItemClick(event, 6, 'scanner');
				}}
				sx={{ color: '#FFFFFF' }}
			>
				<ListItemIcon>
					<DocumentScannerIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Scanner" />
			</ListItemButton>
			<ListItemButton
				selected={selectedIndex === 7}
				style={{ backgroundColor: selectedIndex === 7 ? '#283237' : '' }}
				onClick={(event) => {
					handleListItemClick(event, 7, 'assignment');
				}}
				sx={{ color: '#FFFFFF' }}
			>
				<ListItemIcon>
					<AssignmentIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Assignment" />
			</ListItemButton>
			<ListItemButton
				selected={selectedIndex === 8}
				style={{ backgroundColor: selectedIndex === 8 ? '#283237' : '' }}
				onClick={(event) => {
					handleListItemClick(event, 8, 'report');
				}}
				sx={{ color: '#FFFFFF' }}
			>
				<ListItemIcon>
					<LibraryBooksIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Report" />
			</ListItemButton>
			<ListItemButton style={{ borderBottom: '1px solid #C4C4C4' }}>
				<ListItemIcon />
				<Typography style={{ fontWeight: 'bold', fontSize: '12px', color: '#C4C4C4' }}>MANAJEMEN</Typography>
			</ListItemButton>

			{userId === '1' ? (
				<ListItemButton
					selected={selectedIndex === 9}
					style={{ backgroundColor: selectedIndex === 9 ? '#283237' : '' }}
					onClick={(event) => {
						handleListItemClick(event, 9, 'user');
					}}
					sx={{ color: '#FFFFFF' }}
				>
					<ListItemIcon>
						<GroupIcon sx={{ color: '#FFFFFF' }} />
					</ListItemIcon>
					<ListItemText primary="User" />
				</ListItemButton>
			) : (
				''
			)}

			<ListItemButton onClick={handleClick} sx={{ color: '#FFFFFF' }}>
				<ListItemIcon>
					<HistoryToggleOffIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Log" />
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{userId === '1' ? (
						<ListItemButton
							selected={selectedIndex === 10}
							style={{ backgroundColor: selectedIndex === 10 ? '#283237' : '' }}
							onClick={(event) => {
								handleListItemClick(event, 10, 'logUser');
							}}
							sx={{ pl: 4, color: '#FFFFFF', backgroundColor: '#425660' }}
						>
							<Typography sx={{ ml: 8, fontSize: '14px' }}>Log User</Typography>
						</ListItemButton>
					) : (
						''
					)}

					<ListItemButton
						selected={selectedIndex === 11}
						style={{ backgroundColor: selectedIndex === 11 ? '#283237' : '' }}
						onClick={(event) => {
							handleListItemClick(event, 11, 'logSOS');
						}}
						sx={{ pl: 4, color: '#FFFFFF', backgroundColor: '#425660' }}
					>
						<Typography sx={{ ml: 8, fontSize: '14px' }}>Log SOS</Typography>
					</ListItemButton>
				</List>
			</Collapse>
			<ListItemButton
				selected={selectedIndex === 12}
				style={{ backgroundColor: selectedIndex === 12 ? '#283237' : '' }}
				onClick={(event) => {
					handleListItemClick(event, 12, 'shiftKerja');
				}}
				sx={{ color: '#FFFFFF' }}
			>
				<ListItemIcon>
					<TodayIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Shift Kerja" />
			</ListItemButton>
			{userId === '1' ? (
				<ListItemButton
					selected={selectedIndex === 13}
					style={{ backgroundColor: selectedIndex === 13 ? '#283237' : '' }}
					onClick={(event) => {
						handleListItemClick(event, 13, 'setting');
					}}
					sx={{ color: '#FFFFFF' }}
				>
					<ListItemIcon>
						<SettingsIcon sx={{ color: '#FFFFFF' }} />
					</ListItemIcon>
					<ListItemText primary="Setting" />
				</ListItemButton>
			) : (
				''
			)}

			<ListItemButton
				selected={selectedIndex === 14}
				style={{ backgroundColor: selectedIndex === 14 ? '#283237' : '' }}
				onClick={handleClickOpen}
				sx={{ color: '#FFFFFF' }}
			>
				<ListItemIcon>
					<LogoutIcon sx={{ color: '#FFFFFF' }} />
				</ListItemIcon>
				<ListItemText primary="Log Out" />
			</ListItemButton>
			<Dialog
				open={exit}
				keepMounted
				onClose={handleClose}
				sx={{ ml: 5 }}
				hideBackdrop
				PaperProps={{
					elevation: 1,
					style: {
						backgroundColor: '#FFEFEF',
						padding: 15
					}
				}}
			>
				<DialogTitle sx={{ mb: 2 }}>
					<Typography style={{ fontFamily: 'Noto Sans', fontSize: '14px', fontWeight: 'bold' }}>
						{'Apakah ingin keluar?'}
					</Typography>
				</DialogTitle>
				<DialogActions style={{ marginLeft: 'auto', marginRight: 'auto' }}>
					<Button variant="contained" color="error" style={{ color: '#FFFFFF' }} onClick={handleClose}>
						Batal
					</Button>
					<Button
						variant="contained"
						style={{ backgroundColor: '#2196F3', color: '#FFFFFF' }}
						onClick={handleLogout}
					>
						Setuju
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ListItems;
