import { GET_DATA_SITE_SUCCESS } from '../constants/actionTypes';
import * as api from '../api';

export const getListSite = (auth) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getListSite(auth);
		console.log(data);
		const dataSite = data.result;
		dispatch({ type: GET_DATA_SITE_SUCCESS, payload: dataSite });
	} catch (error) {
		console.log(error);
	}
};
