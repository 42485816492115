import { GET_DATA_SITE_SUCCESS, ADD_SITE_SUCCESS, DELETE_SITE_SUCCESS } from '../constants/actionTypes';

const initialState = {
	site: [],
	loading: true,
	error: ''
};

export default (dataSite = initialState, action) => {
	switch (action.type) {
		case GET_DATA_SITE_SUCCESS:
			return {
				...dataSite,
				loading: false,
				site: action.payload
			};
		case ADD_SITE_SUCCESS:
		   dataSite['loading'] = false;
		   let dataTemp = dataSite['site']
		   dataTemp = [...dataTemp, action.payload];
		   dataSite['site'] = dataTemp;
		   return dataSite;
		case DELETE_SITE_SUCCESS:
	     return { ...dataSite,
	              loading: false,
	              site: action.payload
	     };
		default:
			return dataSite;
	}
};
