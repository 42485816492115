import { GET_DATA_DRIVER_SUCCESS, ADD_DRIVER_SUCCESS, DELETE_DRIVER_SUCCESS } from '../constants/actionTypes';

const initialState = {
  driver:[],
  loading: true,
  error: ""
};

export default (dataDriver = initialState, action) => {
  switch(action.type){
    case GET_DATA_DRIVER_SUCCESS:
    return { ...dataDriver,
             loading: false,
             driver: action.payload
    };
    case ADD_DRIVER_SUCCESS:
      dataDriver['loading'] = false;
      let dataTemp = dataDriver['driver']
      dataTemp = [...dataTemp, action.payload];
      dataDriver['driver'] = dataTemp;
      return dataDriver;
    case DELETE_DRIVER_SUCCESS:
    return { ...dataDriver,
             loading: false,
             driver: action.payload
    };
    default:
      return dataDriver;
  }
}
