import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useStyles from './styles';
import { getVehicleType } from '../../../actions/vehicle';
import { ADD_VEHICLE_TYPE_SUCCESS } from '../../../constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
require('dotenv').config();
const theme = createTheme();

export default function DataForm({ dataForm }) {
	const [ vitName, setVitName ] = useState('');
	const [ showdataForm, setShowDataForm ] = useState(false);
	const [ open, setOpen ] = useState(false);
	const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));
	const data = useSelector((state) => state.data);

	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();

		const resp = await axios({
			method: 'post',
			url: `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/type`,
			withCredentials: false,
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
				'Content-Type': 'application/json',
				Accept: '*/*',
				token: auth
			},
			data: {
				vit_name: vitName
			}
		});

		const json = await resp;
		console.log(json);

		if (json.data) {
			dispatch({ type: ADD_VEHICLE_TYPE_SUCCESS, payload: json.data });
			dispatch(getVehicleType(auth));
			setOpen(false);
		} else {
			console.log('error');
		}
	};

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<ThemeProvider theme={theme}>
			<Button variant="outlined" sx={{ ml: 1, height: '58px', color: '#0C8D19' }} onClick={handleOpen}>
				<AddIcon style={{ color: '#0C8D19' }} />
			</Button>
			<Modal
				hideBackdrop
				open={open}
				onClose={handleClose}
				aria-labelledby="child-modal-title"
				aria-describedby="child-modal-description"
			>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<Paper className={classes.paper2} sx={{ mt: '10%' }}>
						<Box className={classes.box}>
							<Typography
								className={classes.text}
								style={{ fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '18px' }}
								sx={{ ml: '2%', mt: '1%' }}
								component="h3"
								variant="h8"
							>
								TAMBAH JENIS
							</Typography>
						</Box>
						<Box component="form" className={classes.form} noValidate sx={{ mt: 3, mb: 4 }}>
							<Grid container spacing={4}>
								<Grid item xs={11} sx={{ ml: 3 }}>
									<TextField
										sx={{ width: '95%' }}
										required
										type="text"
										value={vitName}
										name="jenisKendaraan"
										onChange={(e) => setVitName(e.target.value)}
										label="Masukan Jenis Kendaraan"
										autoComplete="jenisKendaraan"
									/>
								</Grid>
							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="outlined"
								color="error"
								sx={{
									mt: 4,
									justifyContent: 'center',
									alignItems: 'center',
									width: '128px',
									height: '45px',
									left: '100.25px',
									borderRadius: '4px'
								}}
								onClick={handleClose}
							>
								Batal
							</Button>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								style={{
									backgroundColor: '#0C8D19',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '6px 6px 6px 8px',
									width: '128px',
									height: '45px',
									left: '125.25px',
									borderRadius: '4px'
								}}
								sx={{ mt: 4 }}
								onClick={handleSubmit}
							>
								Save
							</Button>
						</Box>
					</Paper>
				</Container>
			</Modal>
		</ThemeProvider>
	);
}
