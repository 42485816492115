import React, { useState, useEffect, useCallback } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import useStyles from './styles';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../actions/setting';
import { ADD_PORT_SUCCESS } from '../../constants/actionTypes';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
require('dotenv').config();

const mdTheme = createTheme();

function DashboardContent() {
	const [ search, setSearch ] = useState('');
	const dataPort = useSelector((state) => state.dataPort);
	const [ port, setPort ] = useState('');
	const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));

	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const getData = useCallback(
		() => {
			if (port == '') {
				dispatch(getUrl(auth));
				const url = dataPort.checkUrl;
				const currentUrl = [];
				if (url) {
					for (let i = 0; i < url.length; i++) {
						currentUrl.push(url[i].apps_url.replace('.loca.lt', ''));
						setPort(currentUrl);
					}
				} else {
					console.log('data HD tidak ada');
				}
			}
		},
		[ [], port ]
	);

	useEffect(
		() => {
			getData();
		},
		[ getData ]
	);

	const handleSearch = (event) => {
		setSearch(event.target.value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		const resp = await axios({
			method: 'post',
			url: `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/setting/url`,
			withCredentials: false,
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
				'Content-Type': 'application/json',
				Accept: '*/*',
				token: auth
			},
			data: {
				url: port
			}
		});

		const json = await resp;
		console.log(json);

		if (json.data) {
			dispatch({ type: ADD_PORT_SUCCESS, payload: json.data });
			dispatch(getUrl(auth));
		} else {
			console.log('error');
		}
	};

	return (
		<ThemeProvider theme={mdTheme}>
			<Grid container spacing={3}>
				{/*Table*/}
				<Grid item xs={12}>
					<Box className={classes.boxContainer}>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell colSpan={2} sx={{ bgcolor: '#37474F' }}>
											<Typography sx={{ color: '#FFFFFF' }} variant="subtitle1">
												<b>PERUBAHAN WEB URL</b>
											</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell sx={{ width: '50%' }}>
											<Typography>
												Fitur untuk merubah IP Address Local server menjadi sebuah URL Domain,
												dengan pengaksesan melalui jaringan Local.
												<br />Contoh : "127.0.0.1" diubah menjadi "localserver.loca.lt"
											</Typography>
										</TableCell>
										<TableCell sx={{ width: '50%' }}>
											<Typography
												className={classes.text}
												style={{ fontWeight: 'bold' }}
												sx={{ ml: 2 }}
											>
												*Masukan link untuk perubahan web URL
											</Typography>
											<Grid
												item
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'flex-start',
													padding: '16px 14px'
												}}
											>
												<TextField
													type="text"
													id="outlined-select-currency"
													label="Select"
													name="port"
													value={port}
													onChange={(e) => setPort(e.target.value)}
													className={classes.input}
												/>
												<Box className={classes.box}>
													<Typography
														style={{ fontSize: '24px', color: '#FFFFFF' }}
														sx={{ mt: 1, ml: 2 }}
													>
														.loca.lt
													</Typography>
												</Box>
											</Grid>
											<Button
												variant="contained"
												color="success"
												onClick={handleSubmit}
												className={classes.btn}
												sx={{ ml: 2 }}
												style={{
													backgroundColor: '#0C8D19[500]',
													color: '#FFFFFF',
													'&:hover': { backgroundColor: '#2196F3[700]' }
												}}
											>
												PERBARUI
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}

export default function Dashboard() {
	return <DashboardContent />;
}
