import { GET_DATA_USERS_SUCCESS, GET_DATA_ROLE_SUCCESS, ADD_USER_SUCCESS, DELETE_USER_SUCCESS, GET_DATA_USERS_BYID_SUCCESS } from '../constants/actionTypes';

const initialState = {
  users:[],
  role:[],
  userActive:[],
  loading: true,
  error: ""
};

export default (dataUser = initialState, action) => {
  switch(action.type){
    case GET_DATA_USERS_SUCCESS:
    return { ...dataUser,
             loading: false,
             users: action.payload
    };
    case GET_DATA_USERS_BYID_SUCCESS:
    return { ...dataUser,
             loading: false,
             userActive: action.payload
    };
    case GET_DATA_ROLE_SUCCESS:
    return { ...dataUser,
             loading: false,
             role: action.payload
    };
    case ADD_USER_SUCCESS:
      dataUser['loading'] = false;
      let dataTemp = dataUser['users']
      dataTemp = [...dataTemp, action.payload];
      dataUser['users'] = dataTemp;
    return dataUser;
    case DELETE_USER_SUCCESS:
				 return {
					 ...dataUser,
					 loading: false,
					 users: action.payload
				 };
    default:
      return dataUser;
  }
}
