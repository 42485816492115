import { makeStyles } from  '@mui/styles';

export default makeStyles((theme) => ({
  container: {
    width: '90vw',
  },
  hover: {
    '&:hover':
    {
      backgroundColor:'#283237',
    }
  },
  listText: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '8px 0px 8px 16px',
    listStyle: 'none',
    height:' 60px',
    'a':{
      textDecoration: 'none',
      color: '#f5f5f5',
      fontSize: '18px',
      width: '95%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0 16px',
      borderRadius: '4px',
    },
    'a:hover': {
      backgroundColor: '#1a83ff',
    }
  },
}));
