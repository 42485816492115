import { GET_DATA_SHIFT_SUCCESS, ADD_SHIFT_SUCCESS, DELETE_SHIFT_SUCCESS } from '../constants/actionTypes';

const initialState = {
	shift: [],
	loading: true,
	error: ''
};

export default (dataShift = initialState, action) => {
	switch (action.type) {
		case GET_DATA_SHIFT_SUCCESS:
			return {
				...dataShift,
				loading: false,
				shift: action.payload
			};
			case ADD_SHIFT_SUCCESS:
				 dataShift['loading'] = false;
				 let dataTemp = dataShift['shift']
				 dataTemp = [...dataTemp, action.payload];
				 dataShift['shift'] = dataTemp;
			 return dataShift;
			case DELETE_SHIFT_SUCCESS:
	     return { ...dataShift,
	              loading: false,
	              shift: action.payload
	     };	
		default:
			return dataShift;
	}
};
