import { GET_DATA_USERS_SUCCESS, GET_DATA_ROLE_SUCCESS, GET_DATA_USERS_BYID_SUCCESS } from '../constants/actionTypes';
import * as api from '../api';

export const getListUsers = (auth) => async (dispatch) => {
  try{
    const { data } = await api.getListUsers(auth);
    console.log(data);
    const dataUser = data.result;
    dispatch({ type: GET_DATA_USERS_SUCCESS, payload: dataUser })
  } catch (error) {
    console.log(error);
  }
}

export const getDataUserById = (auth, user_id) => async (dispatch) => {
  try{
    const { data } = await api.getDataUserById(auth, user_id);
    console.log(data);
    const dataUser = data.result;
    dispatch({ type: GET_DATA_USERS_BYID_SUCCESS, payload: dataUser })
  } catch (error) {
    console.log(error);
  }
}

export const getListRole = (auth) => async (dispatch) => {
  try{
    const { data } = await api.getListRole(auth);
    console.log(data);
    const dataRole = data.result;
    dispatch({ type: GET_DATA_ROLE_SUCCESS, payload: dataRole })
  } catch (error) {
    console.log(error);
  }
}
