import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import moment from 'moment';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import makeStyles from './styles';
import { getListCategory } from '../../actions/category';
import { DELETE_CATEGORY_SUCCESS } from '../../constants/actionTypes';
import FormJenisMuatan from './JenisMuatanForm/JenisMuatanForm';
import EditJenisMuatanForm from './JenisMuatanForm/EditJenisMuatanForm';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBar from 'material-ui-search-bar';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
require('dotenv').config();
function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [ el, index ]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: 'no',
		numeric: false,
		disablePadding: true,
		label: 'NO'
	},
	{
		id: 'name',
		numeric: true,
		disablePadding: false,
		label: 'KODE MUATAN'
	},
	{
		id: 'detail',
		disablePadding: false,
		label: 'DETAIL MUATAN'
	},
	{
		id: 'description',
		disablePadding: false,
		label: 'KETERANGAN'
	},
	{
		id: 'aksi',
		disablePadding: false,
		label: 'AKSI'
	}
];

function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const classes = makeStyles();
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel
							className={classes.tableText}
							sx={{ color: '#5D7078' }}
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf([ 'asc', 'desc' ]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const EnhancedTableToolbar = (props) => {
	const classes = makeStyles();

	const { numSelected } = props;

	return (
		<Box
			sx={{
				backgroundColor: '#37474F',
				color: '#FFFFFF',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'flex-start',
				borderRadius: '5px 5px 0px 0px',
				padding: '3px',
				height: '36px'
			}}
		>
			<Typography
				sx={{ ml: 2 }}
				style={{ fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '18px' }}
				variant="h6"
				id="tableTitle"
				component="div"
			>
				TABEL DATA JENIS MUATAN
			</Typography>
		</Box>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
};

export default function EnhancedTable() {
	const dispatch = useDispatch();
	const location = useLocation();
	const classes = makeStyles();

	const [ order, setOrder ] = useState('asc');
	const [ loading, setLoading ] = useState(true);
	const [ orderBy, setOrderBy ] = useState('name');
	const [ selected, setSelected ] = useState([]);
	const [ page, setPage ] = useState(0);
	const [ dense, setDense ] = useState(false);
	const [ rowsPerPage, setRowsPerPage ] = useState(10);
	const [ dateStart, setDateStart ] = useState(null);
	const [ dateEnd, setDateEnd ] = useState(null);
	const [ searched, setSearched ] = useState('');
	const [ open, setOpen ] = useState(false);
	const dataCategory = useSelector((state) => state.dataCategory);
	const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));
	const [ downloaded, setDownloaded ] = useState(false);
	const [ addDays, setAddDays ] = useState(false);
	const [ jenisMuatanForm, setJenisMuatanForm ] = useState(false);
	const [ getId, setGetId ] = useState();
	const timer = useRef();
	const [ downloadProgress, setDownloadProgress ] = useState(false);
	const startDate = new Date();

	const handleShowformJenisMuatan = () => setJenisMuatanForm((prevJenisMuatanForm) => !prevJenisMuatanForm);

	useEffect(() => {
		dispatch(getListCategory(auth));
		console.log(dataCategory);
	}, []);

	timer.current = window.setTimeout(() => {
		setLoading(false);
	}, 1500);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = dataCategory.category.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		console.log(rowsPerPage);
		setPage(0);
	};

	const handleClickOpen = (id) => {
		if (id !== null) {
			setOpen(true);
			setGetId(id);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const deleteData = async () => {
		const resp = await axios({
			method: 'post',
			url: `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/category/delete`,
			withCredentials: false,
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
				'Content-Type': 'application/json',
				Accept: '*/*',
				token: auth
			},
			data: {
				cat_id: getId
			}
		});

		const json = await resp;
		console.log(json);

		if (json.data) {
			dispatch({ type: DELETE_CATEGORY_SUCCESS, payload: json.data });
			dispatch(getListCategory(auth));
			setOpen(false);
		} else {
			console.log('error');
		}
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataCategory.category.length) : 0;

	return (
		<Grid container spacing={3}>
			<Grid item sx={{ width: '100vw', display: 'flex', flexDirection: 'row' }}>
				{/* Search */}
				<Grid item xs={12} md={6} lg={9}>
					<Box
						component="form"
						sx={{
							p: '2px 4px',
							display: 'flex',
							alignItems: 'center'
						}}
						noValidate
						autoComplete="off"
					>
						<TextField
							style={{ width: '30vw', backgroundColor: '#FFFFFF', zIndex: 0 }}
							value={searched}
							onChange={(event) => {
								setSearched(event.target.value);
							}}
							variant="outlined"
							label="Search"
						/>
						<SearchIcon
							style={{ color: 'rgba(0, 0, 0, 0.54)', zIndex: '0' }}
							sx={{ ml: -6, width: '46px' }}
						/>
					</Box>
				</Grid>
				<FormJenisMuatan />
			</Grid>
			{/*Table*/}
			<Grid item xs={12}>
				<Box sx={{ width: '100%' }}>
					<Paper sx={{ maxWidth: '100vw', mb: 2 }}>
						<EnhancedTableToolbar numSelected={selected.length} />
						<TableContainer>
							<Table aria-labelledby="tableTitle" size="small">
								<EnhancedTableHead
									numSelected={selected.length}
									order={order}
									orderBy={orderBy}
									onSelectAllClick={handleSelectAllClick}
									onRequestSort={handleRequestSort}
									rowCount={dataCategory.category.length}
								/>
								{!dataCategory.category.length ? (
									<div>
										{loading ? (
											<div>
												<CircularProgress />
											</div>
										) : (
											<Typography
												sx={{ ml: 2 }}
												style={{ fontFamily: 'Noto Sans', fontSize: '14px' }}
											>
												{' '}
												Data tidak ditemukan{' '}
											</Typography>
										)}
									</div>
								) : (
									<TableBody>
										{stableSort(dataCategory.category, getComparator(order, orderBy))
											.filter((category) => {
												if (searched == '') {
													return category;
												} else if (
													category.name.toLowerCase().includes(searched.toLowerCase()) ||
													category.detail.toLowerCase().includes(searched.toLowerCase())
												) {
													return category;
												}
											})
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((category, index) => (
												<TableRow key={category.cat_id}>
													<TableCell
														style={{ fontFamily: 'Noto Sans', fontSize: '14px' }}
														component="th"
													>
														{page * rowsPerPage + index + 1}
													</TableCell>
													<TableCell style={{ fontFamily: 'Noto Sans', fontSize: '14px' }}>
														{category.name}
													</TableCell>
													<TableCell style={{ fontFamily: 'Noto Sans', fontSize: '14px' }}>
														{category.detail}
													</TableCell>
													<TableCell style={{ fontFamily: 'Noto Sans', fontSize: '14px' }}>
														{category.description}
													</TableCell>
													<TableCell>
														<IconButton>
															<DeleteIcon
																onClick={() => handleClickOpen(category.cat_id)}
																sx={{ color: '#F44336' }}
															/>
														</IconButton>
														<Dialog
															open={open}
															key={category.cat_id}
															keepMounted
															onClose={handleClose}
															sx={{ ml: 5 }}
															hideBackdrop
															PaperProps={{
																elevation: 1,
																style: {
																	backgroundColor: '#FFEFEF',
																	padding: 15
																}
															}}
														>
															<DialogTitle sx={{ mb: 2 }}>
																<Typography
																	style={{
																		fontFamily: 'Noto Sans',
																		fontSize: '14px',
																		fontWeight: 'bold'
																	}}
																>
																	{'Apakah yakin ingin menghapus ?'}
																</Typography>
															</DialogTitle>
															<DialogActions
																style={{ marginLeft: 'auto', marginRight: 'auto' }}
															>
																<Button
																	variant="contained"
																	color="error"
																	style={{ color: '#FFFFFF' }}
																	onClick={handleClose}
																>
																	Batal
																</Button>
																<Button
																	variant="contained"
																	style={{
																		backgroundColor: '#2196F3',
																		color: '#FFFFFF'
																	}}
																	onClick={deleteData}
																>
																	Setuju
																</Button>
															</DialogActions>
														</Dialog>
														<IconButton>
															<EditJenisMuatanForm
																namaMuatan={category.name}
																detailMuatan={category.detail}
																keterangan={category.description}
																catId={category.cat_id}
															/>
														</IconButton>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								)}
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[ 10, 25, 50 ]}
							component="div"
							count={dataCategory.category.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>
				</Box>
			</Grid>
		</Grid>
	);
}
