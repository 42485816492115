import React, { useState, useEffect, useCallback } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getListDriver } from '../../../actions/driver';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
require('dotenv').config();
const theme = createTheme();

export default function DataForm({ dataForm, userId, name, birth, gender, address }) {
	const [ namaDriver, setNamaDriver ] = useState(name);
	const [ dob, setDob ] = useState(birth);
	const [ jenisKelamin, setJenisKelamin ] = useState(gender);
	const [ alamatLengkap, setAlamatLengkap ] = useState(address);
	const [ selectedUser, setSelectedUser ] = useState();
	const [ showdataForm, setShowDataForm ] = useState(false);
	const [ open, setOpen ] = useState(false);
	const dataDriver = useSelector((state) => state.dataDriver);
	const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));
	const [ data, setData ] = useState([]);
	const [ dataValid, setDataValid ] = useState(true);

	useEffect(() => {
		dispatch(getListDriver(auth));
		console.log(dataDriver);
	}, []);

	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const genders = [ { id: 'Laki-Laki', name: 'Laki-Laki' }, { id: 'Perempuan', name: 'Perempuan' } ];

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (namaDriver !== '' && dob !== '' && jenisKelamin !== '' && alamatLengkap !== '') {
			const resp = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/driver/update`,
				withCredentials: false,
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
					'Content-Type': 'application/json',
					Accept: '*/*',
					token: auth
				},
				data: {
					user_id: userId,
					name: namaDriver,
					dob: dob,
					gender: jenisKelamin,
					address: alamatLengkap
				}
			});

			const json = await resp;
			console.log(json);

			if (json.data) {
				dispatch(getListDriver(auth));
				setOpen(false);
			} else {
				console.log('error');
			}
		} else {
			setDataValid(false);
		}
	};

	const handleOpen = (id) => {
		if (id !== null) {
			setOpen(true);
		}
	};

	const handleClose = () => {
		setOpen(false);
		setDataValid(true);
	};

	return (
		<ThemeProvider theme={theme}>
			<EditIcon sx={{ color: '#2196F3' }} onClick={() => handleOpen(userId)} />
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.driverFormContainer}
			>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<Paper className={classes.paper} sx={{ mt: '7vh' }} style={{ borderRadius: '10px' }}>
						<Box className={classes.box}>
							<Typography
								className={classes.text}
								style={{ fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '18px' }}
								sx={{ ml: '2%', mt: '1%' }}
								component="h3"
								variant="h8"
							>
								FORM DRIVER
							</Typography>
						</Box>
						<Container component="form" className={classes.form} noValidate sx={{ mt: 4 }}>
							{!dataValid ? (
								<div>
									<Alert severity="error" sx={{ mt: 3, mb: 2 }}>
										Lengkapi Semua Data (*)
									</Alert>
								</div>
							) : null}
							<Grid container spacing={4}>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										type="text"
										value={namaDriver}
										name="namaDriver"
										onChange={(e) => setNamaDriver(e.target.value)}
										id={'namaDriver'}
										label="Nama Driver"
										autoComplete="namaDriver"
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										type="text"
										value={dob}
										name="ttl"
										onChange={(e) => setDob(e.target.value)}
										id="ttl"
										label="Tempat, Tanggal Lahir"
										autoComplete="ttl"
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										select
										type="text"
										value={jenisKelamin}
										name="jenisKelamin"
										onChange={(e) => setJenisKelamin(e.target.value)}
										id="jenisKelamin"
										label="Jenis Kelamin"
										autoComplete="jenisKelamin"
									>
										{genders.map((gender) => (
											<MenuItem key={gender.id} value={gender.name}>
												{gender.name}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										multiline
										rows={4}
										type="text"
										value={alamatLengkap}
										name="alamatLengkap"
										onChange={(e) => setAlamatLengkap(e.target.value)}
										id="alamatLengkap"
										label="Alamat Lengkap"
										autoComplete="alamatLengkap"
									/>
								</Grid>
							</Grid>

							<Container style={{ padding: '20px' }}>
								{/* <Container > */}
								<Grid container style={{ display: 'flex', flexDirection: 'row' }}>
									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											fullWidth
											variant="outlined"
											color="error"
											sx={{
												justifyContent: 'center',
												alignItems: 'center',
												width: '100%',
												height: '45px',
												borderRadius: '4px'
											}}
											onClick={handleClose}
										>
											Batal
										</Button>
									</Grid>

									<Grid item xs={2} md={2} lg={2} />

									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											style={{
												backgroundColor: '#0C8D19',
												justifyContent: 'center',
												alignItems: 'center',
												padding: '6px 6px 6px 8px',
												width: '100%',
												height: '45px',
												borderRadius: '4px'
											}}
											onClick={handleSubmit}
										>
											Simpan
										</Button>
									</Grid>
								</Grid>
							</Container>
						</Container>
					</Paper>
				</Container>
			</Modal>
		</ThemeProvider>
	);
}
