import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from './Component/LoginForm/LoginForm';
import Dashboard from './Component/Dashboard/Dashboard';
import Home from './Component/DashboardHome/Home';
import Driver from './Component/Driver/Dashboard';
import Kendaraan from './Component/DataKendaraan/Dashboard';
import AsalMuatan from './Component/Muatan/Dashboard';
import JenisMuatan from './Component/JenisMuatan/Dashboard';
import SiteJembatan from './Component/SiteJembatan/Dashboard';
import Scanner from './Component/Scanner/Dashboard';
import Assignment from './Component/Assignment/Dashboard';
import User from './Component/User/Dashboard';
import LogUser from './Component/LogUser/Dashboard';
import ShiftKerja from './Component/ShiftKerja/Dashboard';
import Report from './Component/Report/Dashboard';
import LogSOS from './Component/LogSOS/Dashboard';
import Setting from './Component/Setting/Dashboard';

const App = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="dashboard" element={<Dashboard />}>
					<Route path="home" element={<Home />} />
					<Route path="driver" element={<Driver />} />
					<Route path="carList" element={<Kendaraan />} />
					<Route path="asalMuatan" element={<AsalMuatan />} />
					<Route path="jenisMuatan" element={<JenisMuatan />} />
					<Route path="siteJembatan" element={<SiteJembatan />} />
					<Route path="scanner" element={<Scanner />} />
					<Route path="assignment" element={<Assignment />} />
					<Route path="report" element={<Report />} />
					<Route path="user" element={<User />} />
					<Route path="logUser" element={<LogUser />} />
					<Route path="logSOS" element={<LogSOS />} />
					<Route path="shiftKerja" element={<ShiftKerja />} />
					<Route path="setting" element={<Setting />} />
				</Route>
			</Routes>
		</Router>
	);
};

export default App;
