import { GET_DATA_SCANNER_SUCCESS, ADD_SCANNER_SUCCESS, DELETE_SCANNER_SUCCESS } from '../constants/actionTypes';

const initialState = {
	scanner: [],
	loading: true,
	error: ''
};

export default (dataScanner = initialState, action) => {
	switch (action.type) {
		case GET_DATA_SCANNER_SUCCESS:
			return {
				...dataScanner,
				loading: false,
				scanner: action.payload
			};
			case ADD_SCANNER_SUCCESS:
			   dataScanner['loading'] = false;
			   let dataTemp = dataScanner['scanner']
			   dataTemp = [...dataTemp, action.payload];
			   dataScanner['scanner'] = dataTemp;
			 return dataScanner;
			 case DELETE_SCANNER_SUCCESS:
 					 return {
 						 ...dataScanner,
 						 loading: false,
 						 scanner: action.payload
 					 };
		default:
			return dataScanner;
	}
};
