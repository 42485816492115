import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getListRole, getListUsers } from '../../../actions/users';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
require('dotenv').config();

const theme = createTheme();

export default function DataForm({ dataForm, userId, userName, emailUser, passwordUser, roleUser }) {
	const [ username, setUsername ] = useState(userName);
	const [ email, setEmail ] = useState(emailUser);
	const [ password, setPassword ] = useState();
	const [ role, setRole ] = useState(roleUser);
	const [ open, setOpen ] = useState(false);
	const dataUser = useSelector((state) => state.dataUser);
	const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));
	const [ dataValid, setDataValid ] = useState(true);

	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getListRole(auth));
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (username !== '' && email !== '' && password !== '' && role !== '') {
			const resp = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/users/update`,
				withCredentials: false,
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
					'Content-Type': 'application/json',
					Accept: '*/*',
					token: auth
				},
				data: {
					user_id: userId,
					role_id: role,
					username: username,
					email: email,
					password: password
				}
			});

			const json = await resp;
			console.log(json);

			if (json.data) {
				dispatch(getListUsers(auth));
				setOpen(false);
				setDataValid(true);
			} else {
				console.log('error');
			}
		} else {
			setDataValid(false);
		}
	};

	const handleOpen = (userId) => {
		if (userId !== null) {
			setOpen(true);
		}
	};

	const handleClose = () => {
		setOpen(false);
		setDataValid(true);
	};

	return (
		<ThemeProvider theme={theme}>
			<EditIcon sx={{ color: '#2196F3' }} onClick={() => handleOpen(userId)} />
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.scannerFormContainer}
			>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<Paper className={classes.paper} sx={{ mt: '7vh' }} style={{ borderRadius: '10px' }}>
						<Box className={classes.box}>
							<Typography
								className={classes.text}
								style={{ fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '18px' }}
								sx={{ ml: '2%', mt: '1%' }}
								component="h3"
								variant="h8"
							>
								FORM USER
							</Typography>
						</Box>
						<Container component="form" className={classes.form} noValidate sx={{ mt: 4 }}>
							{!dataValid ? (
								<div>
									<Alert severity="error" sx={{ mt: 3, mb: 2 }}>
										Lengkapi Semua Data (*)
									</Alert>
								</div>
							) : null}
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										type="text"
										value={username}
										name="username"
										onChange={(e) => setUsername(e.target.value)}
										label="Username"
										autoComplete="username"
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										multiline
										type="email"
										value={email}
										name="email"
										onChange={(e) => setEmail(e.target.value)}
										label="Email"
										autoComplete="email"
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										type="password"
										value={password}
										name="password"
										onChange={(e) => setPassword(e.target.value)}
										helperText="Masukan password baru jika ingin merubahnya"
										label="Password"
										autoComplete="password"
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										select
										type="text"
										value={role}
										name="jenis"
										onChange={(e) => setRole(e.target.value)}
										label="Jenis"
										autoComplete="jenis"
									>
										{dataUser.role.map((data) => (
											<MenuItem key={data.role_id} value={data.role_id}>
												{data.type}
											</MenuItem>
										))}
									</TextField>
								</Grid>
							</Grid>

							<Container style={{ padding: '20px' }}>
								{/* <Container > */}
								<Grid container style={{ display: 'flex', flexDirection: 'row' }}>
									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											fullWidth
											variant="outlined"
											color="error"
											sx={{
												justifyContent: 'center',
												alignItems: 'center',
												width: '100%',
												height: '45px',
												borderRadius: '4px'
											}}
											onClick={handleClose}
										>
											Batal
										</Button>
									</Grid>

									<Grid item xs={2} md={2} lg={2} />

									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											style={{
												backgroundColor: '#0C8D19',
												justifyContent: 'center',
												alignItems: 'center',
												padding: '6px 6px 6px 8px',
												width: '100%',
												height: '45px',
												borderRadius: '4px'
											}}
											onClick={handleSubmit}
										>
											Simpan
										</Button>
									</Grid>
								</Grid>
							</Container>
						</Container>
					</Paper>
				</Container>
			</Modal>
		</ThemeProvider>
	);
}
