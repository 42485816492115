import { GET_URL_SUCCESS, ADD_PORT_SUCCESS } from '../constants/actionTypes';

const initialState = {
	checkUrl: [],
	url:null,
	loading: true,
	error: ''
};

export default (dataPort = initialState, action) => {
	switch (action.type) {
		case GET_URL_SUCCESS:
			return {
				...dataPort,
				loading: false,
				checkUrl: action.payload
			};
		case ADD_PORT_SUCCESS:
			return {
				...dataPort,
				loading: false,
				url: action.payload
			};
		default:
			return dataPort;
	}
};
