import { GET_DATA_MUATAN_SUCCESS } from '../constants/actionTypes';
import * as api from '../api';

export const getListCity = (auth) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getListCity(auth);
		console.log(data);
		const dataMuatan = data.result;
		dispatch({ type: GET_DATA_MUATAN_SUCCESS, payload: dataMuatan });
	} catch (error) {
		console.log(error);
	}
};
