import React, {useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from './styles';
import Grid from '@mui/material/Grid';
import { getLastLogWeighTotal, getTotalHighDirty, getTotalMediumClean, getTotalMediumDirty, getTotalMedium, getTopTruck, getTopMuatan } from '../../actions/vehicle';
import CircularProgress from '@mui/material/CircularProgress';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Notif from './Notif';
import { isSameWeek } from 'date-fns';
import * as moment from 'moment';
import { Container } from '@mui/material';
import { color, fontSize } from '@mui/system';
import axios from 'axios';
import Cookies from 'universal-cookie';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
require('dotenv').config();
const cookies = new Cookies();

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [ el, index ]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: 'no',
		disablePadding: false,
		label: 'NO'
	},
	{
		id: 'vin',
		disablePadding: false,
		label: 'NO LAMBUNG'
	},
	{
		id: 'HD',
		disablePadding: false,
		label: 'HD(KG)'
	},
	{
		id: 'MD',
		disablePadding: false,
		label: 'MD(KG)'
	},
	{
		id: 'MC',
		disablePadding: false,
		label: 'MC(KG)'
	},
	{
		id: 'TOT',
		disablePadding: false,
		label: 'TOTAL(KG)'
	},
];

function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
		const classes = makeStyles();
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							className={classes.tableText}
							sx={{  color: '#5D7078'}}
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf([ 'asc', 'desc' ]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const EnhancedTableToolbar = (props) => {
	const classes = makeStyles();

	const { numSelected } = props;

	return (
		<Box
			sx={{
				backgroundColor: '#37474F',
				color: '#FFFFFF',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'flex-start',
				borderRadius: '5px 5px 0px 0px',
				padding:'3px',
				height:'36px'
			}}
		>
			<Typography
				sx={{ml:2}}
				style={{ fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '18px' }}
				variant="h6"
				id="tableTitle"
				component="div"
			>
				TABEL MUATAN TERBARU
			</Typography>
		</Box>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
};

// High Dirty
const getHighDirty = (data, label) => ({
	chart: { height: 225 },
	title: {
		text: '',
		style: {
			fontFamily:'Noto Sans',
			fontWeight:'bold',
			fontSize:'12px',
			color:'#7CB5EC',
		}
	},
	yAxis: {
		min: 0,
		title: {
			text: 'Kilogram (KG)',
			style: {
				fontSize: '9',
				color: '#E6E6E6'
			}
		},
	},
	xAxis: {
				categories: label,
		labels: {
			style: {
				color: '#666666'
			}
		}
		},
		series: [{
		name: 'High Dirty',
				type: 'column',
				color: '#7CB5EC',
				// data: dataKendaraan.totalHD,
					data: data,
				showInLegend: false
		}]
});

// Medium Dirty
const getMediumDirty = (data, label) => ({
chart: { height: 225 },
	title: {
		text: '',
		style: {
			fontFamily:'Noto Sans',
			fontWeight:'bold',
			fontSize:'12px',
			color: '#90ED7D',
		}
	},
	yAxis: {
		min: 0,
		title: {
			text: 'Kilogram (KG)',
			style: {
				fontSize: '9',
				color: '#E6E6E6'
			}
		},
	},
	xAxis: {
				categories: label,
		labels: {
			style: {
				color: '#666666'
			}
		}
		},
		series: [{
		name: 'Medium Dirty',
				type: 'column',
				color: '#90ED7D',
				// data: dataKendaraan.totalHD,
				data: data,
				showInLegend: false
		}]
});

// Medium Clean
const getMediumClean = (data, label) => ({
	chart: { height: 225 },
	title: {
		text: '',
		style: {
			fontFamily:'Noto Sans',
			fontWeight:'bold',
			fontSize:'12px',
			color:'#F7A35C',
		}
	},
	yAxis: {
		min: 0,
		title: {
			text: 'Kilogram (KG)',
			style: {
				fontSize: '9',
				color: '#E6E6E6'
			}
		},
	},
	xAxis: {
				categories: label,
		labels: {
			style: {
				color: '#666666'
			}
		}
		},
		series: [{
		name: 'Medium Clean',
				type: 'column',
				color: '#F7A35C',
				// data: dataKendaraan.totalHD,
				data: data,
				showInLegend: false
		}]
});

// Top 3 Truck
const getTopDataTruck = (data, label) => ({
	chart: { height: 225 },
	title: {
		text: '',
		style: {
			fontFamily:'Noto Sans',
			fontWeight:'bold',
			fontSize:'12px',
			color:'#B2B4B1',
		}
	},
	yAxis: {
		min: 0,
		title: {
			text: 'Kilogram (KG)',
			style: {
				fontSize: '9',
				color: '#E6E6E6'
			}
		},
	},
	xAxis: {
				categories: label,
		labels: {
			style: {
				color: '#666666'
			}
		}
		},
		series: [{
		name: 'Top 3 Truck',
				type: 'column',
				color: '#B2B4B1',
				// data: dataKendaraan.totalHD,
				data: data,
				showInLegend: false
		}]
});

const initialState = {
	dataHD: [],
	labelHD: [],
	dataMD: [],
	labelMD:[],
	dataMC:[],
	labelMC:[],
	topTruck:[],
	vinTruck:[],
	totalMuatan:[],
	tanggalMuatan:[],
 	idMuatan:[]
};

export default function EnhancedTable() {

	const dispatch = useDispatch();

	const [ order, setOrder ] = useState('desc');
	const [ orderBy, setOrderBy ] = useState('vin');
	const [ selected, setSelected ] = useState([]);
	const [ page, setPage ] = useState(0);
	const [ dense, setDense ] = useState(false);
	const [ typeHD, setTypeHD ] = useState('monthly');
	const [ typeMD, setTypeMD ] = useState('monthly');
	const [ typeMC, setTypeMC ] = useState('monthly');
	const [ typeTotal, setTypeTotal ] = useState('monthly');
	const [ typeMuatan, setTypeMuatan ] = useState('monthly');
	const [ typeTruck, setTypeTruck ] = useState('monthly');
	const [ rowsPerPage, setRowsPerPage ] = useState(5);
	const dataKendaraan = useSelector((state) => state.dataKendaraan);
	const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));
	const [ totalTruck, setTotalTruck] = useState([]);
	const timer = useRef();
  const [loading, setLoading] = useState(true);
	const [ inputData, setInputData ] = useState(initialState);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const classes = makeStyles();

	const current = new Date();
	const date =moment(current).format('YYYY-MM-DD');

	// Total Medium
	const [options4, setOptions4] = useState({
		chart: { height: 225 },
		title: {
			text: '',
			style: {
				fontFamily:'Noto Sans',
				fontWeight:'bold',
				fontSize:'12px',
				color:'#2196F3',
			}
		},
		yAxis: {
			min: 0,
			title: {
				text: 'Kilogram (KG)',
				style: {
					fontSize: '9',
					color: '#E6E6E6'
				}
			},
		},
		xAxis: {
					categories: ['HD', 'MD', 'MC'],
			labels: {
				style: {
					color: '#666666'
				}
			}
			},
			series: [{
			name: 'Total',
					type: 'column',
					color: '#2196F3',
					// data: dataKendaraan.totalHD,
					data: [],
					showInLegend: false
			}]
	});

	// Top 3 Muatan
	const [options5, setOptions5] = useState({
		chart: { height: 225 },
		title: {
			text: '',
			style: {
				fontFamily:'Noto Sans',
				fontWeight:'bold',
				fontSize:'12px',
				color:'#B2B4B1',
			}
		},
		yAxis: {
			min: 0,
			title: {
				text: 'Kilogram (KG)',
				style: {
					fontSize: '9',
					color: '#E6E6E6'
				}
			},
		},
		xAxis: {
					categories: ['HD', 'MD', 'MC'],
			labels: {
				style: {
					color: '#666666'
				}
			}
			},
			series: [{
			name: 'Top 3 Muatan',
					type: 'column',
					color: '#B2B4B1',
					// data: dataKendaraan.totalHD,
					data: [],
					dataLabels: {
						enabled: true,
						format: []
					},
					showInLegend: false
			}]
	});

	const handleItemClickHD = (event, newType) => {
    setTypeHD(newType);
  };

	const handleItemClickMD = (event, newType) => {
    setTypeMD(newType);
  };

	const handleItemClickMC = (event, newType) => {
    setTypeMC(newType);
  };

	const handleItemClickTotal = (event, newType) => {
    setTypeTotal(newType);
  };

	const handleItemClickTopMuatan = (event, newType) => {
		setTypeMuatan(newType)
  };

	const handleItemClickTopTruck = (event, newType) => {
    setTypeTruck(newType);
  };


	const getType = useCallback(async () => {

		/* High Dirty */
		if(typeHD === 'monthly' || typeHD === 'weekly')
		{
			const resp = await axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/hd?date=${date}&type=${typeHD}`, {
					headers: {
						'Content-Type': 'application/json',
						Accept: '*/*',
						token: auth
					},
					withCredentials: false
			})

			const json = await resp;
			console.log(json);
			const dataHd = json.data.result;
			const currentTotalHD = [];
			const currentLabelHD = [];

			if(dataHd.length !== 0)
			{
				for(let i=0; i < dataHd.length; i++)
				{
						currentTotalHD.push(parseInt(dataHd[i].total))
						currentLabelHD.push(moment(dataHd[i].label).format('MMM D'))
						setInputData(({inputData, ['dataHD'] : currentTotalHD, ['labelHD'] : currentLabelHD }))
				}
					console.log(currentTotalHD, currentLabelHD, inputData.dataHD, inputData.labelHD);
			} else {
				console.log('data HD tidak ada')
			}
		}
		if(typeMD === 'monthly' || typeMD === 'weekly')
		{
			/* Medium Dirty */
			const respMD = await axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/md?date=${date}&type=${typeMD}`, {
					headers: {
						'Content-Type': 'application/json',
						Accept: '*/*',
						token: auth
					},
					withCredentials: false
			})

			const jsonMD = await respMD;
			console.log(jsonMD);

			const dataMD = jsonMD.data.result;
			const currentTotalMD = [];
			const currentLabelMD = [];

			if(dataMD.length !== 0)
			{
				for(let i=0; i < dataMD.length; i++)
				{
						currentTotalMD.push(parseInt(dataMD[i].total))
						currentLabelMD.push(moment(dataMD[i].label).format('MMM D'))
						setInputData(({inputData, ['dataMD'] : currentTotalMD, ['labelMD'] : currentLabelMD }))
				}
			} else {
				console.log('Data Tidak Ditemukan')
			}
		}
		if(typeMC === 'monthly' || typeMC === 'weekly')
		{
			/* Medium Clean */
			const respMC = await axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/mc?date=${date}&type=${typeMC}`, {
					headers: {
						'Content-Type': 'application/json',
						Accept: '*/*',
						token: auth
					},
					withCredentials: false
			})

			const jsonMC = await respMC;
			console.log(jsonMC);

			const dataMC = jsonMC.data.result;
			const currentTotalMC = [];
			const currentLabelMC = [];

			if(dataMC.length !== 0)
			{
				for(let i=0; i < dataMC.length; i++)
				{
						currentTotalMC.push(parseInt(dataMC[i].total))
						currentLabelMC.push(moment(dataMC[i].label).format('MMM D'))
						setInputData(({inputData, ['dataMC'] : currentTotalMC, ['labelMC'] : currentLabelMC }))
				}
			} else {
				console.log('Data tidak ditemukan')
			}
		}

		if(typeTotal === 'monthly' || typeTotal === 'weekly')
		{
			/* Total Medium */
			const respM = await axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/total?date=${date}&type=${typeTotal}`, {
					headers: {
						'Content-Type': 'application/json',
						Accept: '*/*',
						token: auth
					},
					withCredentials: false
			})

			const jsonM = await respM;
			console.log(jsonM);

			const dataM = jsonM.data.result;
			console.log(dataM);

			if(dataM.length !== 0)
			{
				dataM.map((m) => (
				setOptions4({ series: [{ data: [parseInt(m.total_hd), parseInt(m.total_md), parseInt(m.total_mc)] }] })
			))
					console.log(options4);
			} else {
				console.log('Data Medium Tidak Ada')
			}
		}
		if(typeTruck === 'monthly' || typeTruck === 'weekly')
		{
			/* Top 3 Truck */
			const respTruck = await axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/top_truck?date=${date}&type=${typeTruck}`, {
					headers: {
						'Content-Type': 'application/json',
						Accept: '*/*',
						token: auth
					},
					withCredentials: false
			})

			const jsonTruck = await respTruck;
			console.log(jsonTruck);

			const dataTruck = jsonTruck.data.result;
			const currentTotalTruck = [];
			const currentLabelTruck = [];

			if(dataTruck.length !== 0)
			{
				for(let i=0; i < dataTruck.length; i++)
				{
						currentTotalTruck.push(parseInt(dataTruck[i].total))
						currentLabelTruck.push(dataTruck[i].veh_vin)
						setInputData(({inputData, ['topTruck'] : currentTotalTruck, ['labelTruck'] : currentLabelTruck }))
				}
			} else {
				console.log('Data Top 3 Truk Tidak Ada')
			}
		}
	if(typeMuatan === 'monthly' || typeMuatan === 'weekly')
	{
		/* Top 3 Muatan */
		const respMuatan = await axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/top_cat?date=${date}&type=${typeMuatan}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: '*/*',
					token: auth
				},
				withCredentials: false
		})

		const jsonMuatan = await respMuatan;
		console.log(jsonMuatan);

		const dataMuatan = jsonMuatan.data.result;
		console.log(dataMuatan);

		const currentDateMuatan = [];
		const currentTotalMuatanHD = [];
		const currentTotalMuatanMD = [];
		const currentTotalMuatanMC = [];

		if(dataMuatan.length !== 0)
		{
			for(let i=0; i < dataMuatan.length; i++)
			{
					if(dataMuatan[i].cat_id === 1)
					{
						currentTotalMuatanHD.push(parseInt(dataMuatan[i].total_weight));
						currentDateMuatan.push(moment(dataMuatan[i].max_date).format('YYYY-MM-DD'));
					} else if(dataMuatan[i].cat_id === 2 )
					{
						currentTotalMuatanMD.push(parseInt(dataMuatan[i].total_weight));
					} else if(dataMuatan[i].cat_id === 3)
					{
						currentTotalMuatanMC.push(parseInt(dataMuatan[i].total_weight));
					} else
					{
						currentTotalMuatanHD.push(parseInt(0));
						currentTotalMuatanMD.push(parseInt(0));
						currentTotalMuatanMC.push(parseInt(0));
					}
					setOptions5({
						series: [{ data: [parseInt(currentTotalMuatanHD), parseInt(currentTotalMuatanMD), parseInt(currentTotalMuatanMC)],
										dataLabels: {
											format: [currentDateMuatan]
										}
						 }],
					})
			}
		} else {
			console.log('Data Top 3 Muatan Tidak Ada')
		}
	}
  }, [typeHD, typeMD, typeMC, typeTotal, typeTruck, typeMuatan]);

	useEffect(async () => {
		dispatch(getLastLogWeighTotal(auth));
		getType()
	}, [getType])

	timer.current = window.setTimeout(() => {
	        setLoading(false);
	}, 1500);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};


	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = dataKendaraan.totalMuatan.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangeDense = (event) => {
		setDense(event.target.checked);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataKendaraan.totalMuatan.length) : 0;

	return (

		<Box>
			{/* baris chart 1 */}
			<Grid container spacing={2} className={classes.container} >

				{/* chart 1 */}
				<Grid item xs={4} lg={4}>
					<Paper
						sx={{
							borderRadius: '5px',
							pb: 2
						}}
					>
						<Grid item sx={{ display:'flex', flexDirection:'row', pt:2.5, ml:2}}>
							<Grid item xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
								<Typography style={{
										fontFamily:'Noto Sans',
										fontWeight:'bold',
										fontSize:'12px',
										color:'#7CB5EC',
										letterSpacing: '0.15px'
									}}
								>High Dirty (HD)
								</Typography>
							</Grid>

							<Grid item justifyContent="flex-end" xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', mr:2 }}>
							<ToggleButtonGroup
								value={typeHD}
								exclusive
								onChange={handleItemClickHD}
							>
								<ToggleButton sx={{mr:'5px'}}
										value="monthly"
										style={{
											padding:'5px 15px',
											// width:'58px',
											height:'27px',
											// top:'29px',
											zIndex:'1',
											// left:'18vw',
											background: typeHD === "monthly" ? '#7CB5EC' : '#FFFFFF',
											color: typeHD === "monthly" ? '#FFFFFF' : '#7CB5EC',
											border:'0px',
											borderRadius: '5px',
											fontFamily:'Noto Sans',
											fontWeight:'bold',
											fontSize:'10px',
										}}
								> Month
								</ToggleButton>
								<ToggleButton value="weekly"
										style={{
											padding:'5px 15px',
											// width:'58px',
											height:'27px',
											// top:'29px',
											zIndex:'1',
											// left:'18vw',
											background: typeHD === 'weekly' ? '#7CB5EC' : '#FFFFFF',
											color:typeHD === 'weekly' ? '#FFFFFF' : '#7CB5EC',
											border:'0px',
											borderRadius: '5px',
											fontFamily:'Noto Sans',
											fontWeight:'bold',
											fontSize:'10px',
										}}
								>Week
								</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>
						<HighchartsReact
								highcharts={Highcharts}
								options={getHighDirty(inputData.dataHD, inputData.labelHD)}
							/>
					</Paper>
				</Grid>

				{/* chart 2 */}
				<Grid item xs={4} lg={4}>
					<Paper
						sx={{
							borderRadius: '5px',
							pb: 2
						}}
					>
						<Grid item sx={{ display:'flex', flexDirection:'row', pt:2.5, ml:2}}>
							<Grid item xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
								<Typography style={{
										fontFamily:'Noto Sans',
										fontWeight:'bold',
										fontSize:'12px',
										color:'#90ED7D',
										letterSpacing: '0.15px'
									}}
								>Medium Dirty (MD)
								</Typography>
							</Grid>

							<Grid item justifyContent="flex-end" xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', mr:2 }}>
							<ToggleButtonGroup
								value={typeMD}
								exclusive
								onChange={handleItemClickMD}
							>
								<ToggleButton
										value='monthly'
										sx={{mr:'5px'}}
										style={{
											padding:'5px 15px',
											// width:'58px',
											height:'27px',
											// top:'29px',
											zIndex:'1',
											// left:'18vw',
											background:typeMD === 'monthly' ? '#90ED7D' : '#FFFFFF',
											color:typeMD === 'monthly' ? '#FFFFFF' : '#90ED7D',
											border:'0px',
											borderRadius: '5px',
											fontFamily:'Noto Sans',
											fontWeight:'bold',
											fontSize:'10px',
										}}
								> Month
								</ToggleButton>
								<ToggleButton
										value='weekly'
										style={{
											padding:'5px 15px',
											// width:'58px',
											height:'27px',
											// top:'29px',
											zIndex:'1',
											// left:'18vw',
											background:typeMD === 'weekly' ? '#90ED7D' : '#FFFFFF',
											color:typeMD === 'weekly' ? '#FFFFFF' : '#90ED7D',
											borderRadius: '5px',
											border:'0px',
											fontFamily:'Noto Sans',
											fontWeight:'bold',
											fontSize:'10px',
										}}
								>Week
								</ToggleButton>
							</ToggleButtonGroup>
							</Grid>
						</Grid>
						<HighchartsReact
								highcharts={Highcharts}
								options={getMediumDirty(inputData.dataMD, inputData.labelMD)}
							/>
					</Paper>
				</Grid>

				{/* chart 3 */}
				<Grid item xs={4} lg={4}>
					<Paper
						sx={{
							borderRadius: '5px',
							pb: 2
						}}
					>
						<Grid item sx={{ display:'flex', flexDirection:'row', pt:2.5, ml:2}}>
							<Grid item xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
								<Typography style={{
										fontFamily:'Noto Sans',
										fontWeight:'bold',
										fontSize:'12px',
										color:'#F7A35C',
										letterSpacing: '0.15px'
									}}
								>Medium Clean (MC)
								</Typography>
							</Grid>

							<Grid item justifyContent="flex-end" xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', mr:2 }}>
							<ToggleButtonGroup
								value={typeMC}
								exclusive
								onChange={handleItemClickMC}
							>
								<ToggleButton
										value='monthly'
										style={{
											padding:'5px 15px',
											// width:'58px',
											height:'27px',
											// top:'29px',
											zIndex:'1',
											// left:'18vw',
											background: typeMC === 'monthly' ? '#F7A35C' : '#FFFFFF',
											color:typeMC === 'monthly' ? '#FFFFFF' : '#F7A35C',
											borderRadius: '5px',
											border:'0px',
											fontFamily:'Noto Sans',
											fontWeight:'bold',
											fontSize:'10px',
										}}
								> Month
								</ToggleButton>
								<ToggleButton
								 		value='weekly'
										style={{
											padding:'5px 15px',
											// width:'58px',
											height:'27px',
											// top:'29px',
											zIndex:'1',
											// left:'18vw',
											background:typeMC === 'weekly' ? '#F7A35C' : '#FFFFFF',
											color:typeMC === 'weekly' ? '#FFFFFF' : '#F7A35C',
											borderRadius: '5px',
											border:'0px',
											fontFamily:'Noto Sans',
											fontWeight:'bold',
											fontSize:'10px',
										}}
								>Week
								</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>
						<HighchartsReact
								highcharts={Highcharts}
								options={getMediumClean(inputData.dataMC, inputData.labelMC)}
							/>
					</Paper>
				</Grid>

			</Grid>

			{/* baris chart 2 */}
			<Grid container spacing={2} className={classes.container} sx={{mt:1}} >

				{/* chart 1 */}
				<Grid item xs={4} lg={4}>
					<Paper
						sx={{
							borderRadius: '5px',
							pb: 2
						}}
					>
						<Grid item sx={{ display:'flex', flexDirection:'row', pt:2.5, ml:2}}>
							<Grid item xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
								<Typography style={{
										fontFamily:'Noto Sans',
										fontWeight:'bold',
										fontSize:'12px',
										color:'#2196F3',
										letterSpacing: '0.15px'
									}}
								>Total
								</Typography>
							</Grid>

							<Grid item justifyContent="flex-end" xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', mr:2 }}>
							<ToggleButtonGroup
								value={typeTotal}
								exclusive
								onChange={handleItemClickTotal}
							>
								<ToggleButton
										value='monthly'
										style={{
											padding:'5px 15px',
											// width:'58px',
											height:'27px',
											// top:'29px',
											zIndex:'1',
											// left:'18vw',
											background:typeTotal === 'monthly' ? '#2196F3' : '#FFFFFF',
											color:typeTotal === 'monthly' ? '#FFFFFF' : '#2196F3',
											borderRadius: '5px',
											border:'0px',
											fontFamily:'Noto Sans',
											fontWeight:'bold',
											fontSize:'10px',
										}}
								> Month
								</ToggleButton>
								<ToggleButton
										value='weekly'
										style={{
											padding:'5px 15px',
											// width:'58px',
											height:'27px',
											// top:'29px',
											zIndex:'1',
											// left:'18vw',
											background:typeTotal === 'weekly' ? '#2196F3' : '#FFFFFF',
											color:typeTotal === 'weekly' ? '#FFFFFF' : '#2196F3',
											borderRadius: '5px',
											border:'0px',
											fontFamily:'Noto Sans',
											fontWeight:'bold',
											fontSize:'10px',
										}}
								>Week
								</ToggleButton>
							</ToggleButtonGroup>
							</Grid>
						</Grid>
						<HighchartsReact
								highcharts={Highcharts}
								options={options4}
							/>
					</Paper>
				</Grid>

				{/* chart 2 */}
				<Grid item xs={4} lg={4}>
					<Paper
						sx={{
							borderRadius: '5px',
							pb: 2
						}}
					>
						<Grid item sx={{ display:'flex', flexDirection:'row', pt:2.5, ml:2}}>
							<Grid item xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
								<Typography style={{
										fontFamily:'Noto Sans',
										fontWeight:'bold',
										fontSize:'12px',
										color:'#B2B4B1',
										letterSpacing: '0.15px',
										height: '27px'
									}}
								>Top 3 Muatan
								</Typography>
							</Grid>
							<Grid item justifyContent="flex-end" xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', mr:2 }}>
							<ToggleButtonGroup
								value={typeMuatan}
								exclusive
								onChange={handleItemClickTopMuatan}
							>
								<ToggleButton
								value='monthly'
								sx={{mr:'5px'}}
										style={{
											padding:'5px 15px',
											// width:'58px',
											height:'27px',
											// top:'29px',
											zIndex:'1',
											// left:'18vw',
											background:typeMuatan === 'monthly' ? '#B2B4B1' : '#FFFFFF',
											color:typeMuatan === 'monthly' ? '#FFFFFF' : '#B2B4B1',
											borderRadius: '5px',
											border:'0px',
											fontFamily:'Noto Sans',
											fontWeight:'bold',
											fontSize:'10px',
										}}
								> Month
								</ToggleButton>
								<ToggleButton
										value='weekly'
										style={{
											padding:'5px 15px',
											// width:'58px',
											height:'27px',
											// top:'29px',
											zIndex:'1',
											// left:'18vw',
											background:typeMuatan === 'weekly' ? '#B2B4B1' : '#FFFFFF',
											color:typeMuatan === 'weekly' ? '#FFFFFF' : '#B2B4B1',
											borderRadius: '5px',
											border:'0px',
											fontFamily:'Noto Sans',
											fontWeight:'bold',
											fontSize:'10px',
										}}
								>Week
								</ToggleButton>
							</ToggleButtonGroup>
							</Grid>
						</Grid>
						<HighchartsReact
								highcharts={Highcharts}
								options={options5}
							/>
					</Paper>
				</Grid>

				{/* chart 3 */}
				<Grid item xs={4} lg={4}>
					<Paper
						sx={{
							borderRadius: '5px',
							pb: 2
						}}
					>
						<Grid item sx={{ display:'flex', flexDirection:'row', pt:2.5, ml:2}}>
							<Grid item xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
								<Typography style={{
										fontFamily:'Noto Sans',
										fontWeight:'bold',
										fontSize:'12px',
										color:'#B2B4B1',
										letterSpacing: '0.15px',
										height: '27px'
									}}
								>Top 3 Truck
								</Typography>
							</Grid>

							<Grid item justifyContent="flex-end" xs={6} lg={6} sx={{display: 'flex', flexDirection: 'row', mr:2 }}>
							<ToggleButtonGroup
								value={typeTruck}
								exclusive
								onChange={handleItemClickTopTruck}
							>
							<ToggleButton
								value='monthly'
								sx={{mr:'5px'}}
									style={{
										padding:'5px 15px',
										// width:'58px',
										height:'27px',
										// top:'29px',
										zIndex:'1',
										// left:'18vw',
										background:typeTruck === 'monthly' ? '#B2B4B1' : '#FFFFFF',
										color:typeTruck === 'monthly' ? '#FFFFFF' : '#B2B4B1',
										borderRadius: '5px',
										border:'0px',
										fontFamily:'Noto Sans',
										fontWeight:'bold',
										fontSize:'10px',
									}}
							> Month
							</ToggleButton>
							<ToggleButton
									value='weekly'
									style={{
										padding:'5px 15px',
										// width:'58px',
										height:'27px',
										// top:'29px',
										zIndex:'1',
										// left:'18vw',
										background:typeTruck === 'weekly' ? '#B2B4B1' : '#FFFFFF',
										color:typeTruck === 'weekly' ? '#FFFFFF' : '#B2B4B1',
										borderRadius: '5px',
										border:'0px',
										fontFamily:'Noto Sans',
										fontWeight:'bold',
										fontSize:'10px',
									}}
							>Week
							</ToggleButton>
							</ToggleButtonGroup>
							</Grid>
						</Grid>
						<HighchartsReact
								highcharts={Highcharts}
								options={getTopDataTruck(inputData.topTruck, inputData.labelTruck)}
							/>
					</Paper>
				</Grid>
			</Grid>

			<Box sx={{ width:'100%', mt: '30px' }}>
					<Paper sx={{ maxWidth: '100vw', mb: 2 }}>
							<EnhancedTableToolbar numSelected={selected.length} />
							<TableContainer>
								<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
									<EnhancedTableHead
										numSelected={selected.length}
										order={order}
										orderBy={orderBy}
										onSelectAllClick={handleSelectAllClick}
										onRequestSort={handleRequestSort}
									/>
									{
										!dataKendaraan.dataTotalTimbang.length?
										<div>
										{ loading ?
											<div>
												<CircularProgress />
											</div>
											:
												<Typography sx={{ml:2}} style={{fontFamily: 'Noto Sans', fontSize:'14px'}} > Data tidak ditemukan </Typography>
										}
										</div>
									:
										<TableBody>
											{stableSort(dataKendaraan.dataTotalTimbang, getComparator(order, orderBy))
												.map((list, index) => (
														<TableRow key={list.veh_id}>
															<TableCell>{index+1}</TableCell>
															<TableCell>{list?.vehicle.map((no) =>
																	<div>
																	{no.vin}
																	</div>
															)}
															</TableCell>
															<TableCell>
															{list?.HD.map((hd) =>
																<div>
																{hd.total}
																</div>
															)}
															</TableCell>
															<TableCell>
																{list?.MD.map((md) =>
																	<div>
																	{md.total}
																	</div>
																)}
																</TableCell>
															<TableCell>
																{list?.MC.map((mc) =>
																	<div>
																	{mc.total}
																	</div>
																)}
															</TableCell>
															<TableCell>
																{list?.TOT.map((tot) =>
																	<div>
																	{tot.total}
																	</div>
																)}
															</TableCell>
														</TableRow>
											))}
									</TableBody>
								}
								</Table>
							</TableContainer>
			</Paper>
			</Box>
		</Box>
	);
}
