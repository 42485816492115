import { makeStyles } from  '@mui/styles';

export default makeStyles((theme) => ({
  paper: {
    width:'30vw',
    left:'537px',
    boxShadow:'0px 0px 3px rgba(0, 0, 0, 0.14)',
  },
  text: {
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color:'#FFFFFF',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: '#37474F',
    borderRadius: '7px 7px 0px 0px',
    height: '36px',
  },
  shiftFormContainer: {
    position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.6)',
  },
  helperText: {
    width:'255px',
    height:'12px',
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontSize: '10px',
    lineHeight: '12px',
    color:'#666666',
  }
}))
