import React, { useState, useRef, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useStyles from './styles';
import Image from '../../Image/Frame (2) 1.png';
import Table from './Table';
import { useDispatch, useSelector } from 'react-redux';
import { getDataUserById } from '../../actions/users';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const mdTheme = createTheme();

function DashboardHome() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const authData = useSelector((state) => state.auth);
  const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));
  const [ userId, user_id ] = useState(decodeURI(cookies.get('user_id')));
  console.log(authData);

	useEffect(() => {
		dispatch(getDataUserById(auth,userId));
	}, [])

  return (
    <ThemeProvider theme={mdTheme}>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} lg={9} sx={{ display:'flex', flexDirection:'row'}}>
            <Paper
                sx={{
                  height: '146px',
                  // width: '66vw',
                  borderRadius: '5px',
                }}
            >
              <img src={Image} align="left" style={{marginLeft:'25px', marginTop:'9px', marginRight:'25px'}} alt="image"/>
              <Box style={{marginTop:'4vh'}}>
                <Typography style={{fontSize: '20px',fontFamily: 'Noto Sans',color:'#5D7078',}}> <span style={{fontWeight: 'bold'}}>Selamat datang </span> di Dashboard Server PT. Telen Orbit Prima Buhut Jaya Village, Sub-district of Central Kapuas, Kapuas, Central Kalimantan</Typography>
              </Box>
            </Paper>
          </Grid>

          {/*user*/}
          <Grid item xs={12} lg={3}>
            <Paper
              sx={{
                // ml:0.5,
                // width: '20vw',
                height: '146px',
                borderRadius: '5px',
              }}
            >
              <Grid item sx={{ display:'flex', flexDirection:'row', pt:2.5, ml:2}}>
                <AccountCircleIcon className={classes.icon} sx={{ width:'60px', mt:-1, height:'60px', color: '#C4C4C4'}}/>
                <Typography sx={{ml:1.5}} style={{fontWeight: 'bold', fontSize: '18px', color: '#5D7078'}}>{dataUser.userActive.name}</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ml:11, mt:-3}} style={{fontWeight: 'normal', fontSize: '10px', color: '#5D7078'}}>{dataUser.userActive ? dataUser.userActive.email : ''}</Typography>
              </Grid>
              <Grid item sx={{mt:3}} style={{borderTop:'1px solid #D1D1D1', marginLeft:'auto', marginRight:'auto'}}>
                  <Typography sx={{mt:2, ml:8 }} style={{ fontSize:'14px', fontFamily: 'Noto Sans', color: '#5D7078' }}> Waktu Login : <span style={{fontWeight: 'bold'}}>{dataUser.userActive.last_seen}</span> </Typography>
              </Grid>
              </Paper>
          </Grid>

        </Grid>

        <Grid container sx={{ mt:3, ml:1 }}> </Grid>

        {/* Table */}
        <Grid container>
          <Box sx={{ width:'100%' }}>
              <Table />
          </Box>
        </Grid>
    </ThemeProvider>
  )
}

export default function Home() {
  return <DashboardHome />;
}
