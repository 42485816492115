import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getListSite } from '../../../actions/site';
import { getListScanner } from '../../../actions/scanner';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const theme = createTheme();
const initialState = { dataScanner: [] };

export default function DataForm({
	dataForm,
	kodeSite,
	namaJembatan,
	lokasiJembatan,
	kodeScanner,
	lokasiId,
	perangkat,
	device_id
}) {
	const [ locCode, setlocCode ] = useState(kodeSite);
	const [ locName, setlocName ] = useState(namaJembatan);
	const [ locDesc, setlocDesc ] = useState(lokasiJembatan);

	const [ deviceId, setDeviceId ] = useState();
	const [ inputScanner, setInputScanner ] = useState(initialState);
	const [ open, setOpen ] = useState(false);
	const dataScanner = useSelector((state) => state.dataScanner);
	const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));
	const [ dataValid, setDataValid ] = useState(true);
	const [ deviceOptions, setDeviceOptions ] = useState([]);
	const [ currDeviceIds, setCurrDeviceIds ] = useState(perangkat.map(value => value.device_id));
	require('dotenv').config();

	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(
		() => {
			dispatch(getListScanner(auth));
		},
		[ deviceId ]
	);

	useEffect(() => {
		setDeviceOptions(dataScanner.scanner);
	}, [dataScanner]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (locCode !== '' && locName !== '' && locDesc !== '' && deviceId !== '') {
			const resp = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/site/update`,
				withCredentials: false,
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
					'Content-Type': 'application/json',
					Accept: '*/*',
					token: auth
				},
				data: {
					loc_id: lokasiId,
					loc_name: locName,
					loc_code: locCode,
					loc_desc: locDesc,
					device_id: deviceId
				}
			});

			const json = await resp;
			// console.log('opoiki' + json);

			if (json.data) {
				dispatch(getListSite(auth));
				setOpen(false);
				setDataValid(true);
				inputScanner.dataScanner = [];
				setDeviceId('');
			} else {
				console.log('error');
			}
		} else {
			setDataValid(false);
		}
	};

	const handleOpen = (lokasiId) => {
		if (lokasiId !== null) {
			setOpen(true);
		}
	};

	const handleClose = () => {
		setOpen(false);
		setDataValid(true);
		setDeviceId('');
		inputScanner.dataScanner = [];

		const deviceIds = perangkat.map(value => value.device_id);
		setCurrDeviceIds([...deviceIds]);
		setDeviceOptions(dataScanner.scanner.filter(value => !deviceIds.includes(value.device_id)))
	};

	return (
		<ThemeProvider theme={theme}>
			<EditIcon sx={{ color: '#2196F3' }} onClick={() => handleOpen(lokasiId)} />
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.siteFormContainer}
			>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<Paper className={classes.paper} sx={{ mt: '7vh' }} style={{ borderRadius: '10px' }}>
						<Box className={classes.box}>
							<Typography
								className={classes.text}
								style={{ fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '18px' }}
								sx={{ ml: '2%', mt: '1%' }}
								component="h3"
								variant="h8"
							>
								FORM SITE MUATAN
							</Typography>
						</Box>
						<Container component="form" className={classes.form} noValidate sx={{ mt: 4 }}>
							{!dataValid ? (
								<div>
									<Alert severity="error" sx={{ mt: 3, mb: 2 }}>
										Lengkapi Semua Data (*)
									</Alert>
								</div>
							) : null}
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										type="text"
										value={locCode}
										name="kodeJembatan"
										onChange={(e) => setlocCode(e.target.value)}
										label="Kode Jembatan"
										autoComplete="kodeJembatan"
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										multiline
										type="text"
										value={locName}
										name="namaJembatan"
										onChange={(e) => setlocName(e.target.value)}
										label="Nama Jembatan"
										autoComplete="namaJembatan"
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										multiline
										rows={5}
										type="text"
										value={locDesc}
										name="lokasiJembatan"
										onChange={(e) => setlocDesc(e.target.value)}
										label="Lokasi Jembatan"
										autoComplete="lokasiJembatan"
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<Autocomplete
										multiple
										id="tags-outlined"
										options={deviceOptions.filter(value => !currDeviceIds.includes(value.device_id))}
										getOptionLabel={(options) => options.device_name}
										filterSelectedOptions
										defaultValue={perangkat}
										onChange={(event, newValue) => {
											const deviceIds = newValue.map(value => value.device_id);
											setCurrDeviceIds([...deviceIds]);
											setDeviceOptions(dataScanner.scanner.filter(value => !deviceIds.includes(value.device_id)))
											setDeviceId(newValue.map((value) => value.device_id))
										}}
										renderInput={(params) => (
											<TextField {...params} label="Kode Scanner" placeholder="Kode Scanner" />
										)}
									/>
								</Grid>
							</Grid>

							<Container style={{ padding: '20px' }}>
								{/* <Container > */}
								<Grid container style={{ display: 'flex', flexDirection: 'row' }}>
									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											fullWidth
											variant="outlined"
											color="error"
											sx={{
												justifyContent: 'center',
												alignItems: 'center',
												width: '100%',
												height: '45px',
												borderRadius: '4px'
											}}
											onClick={handleClose}
										>
											Batal
										</Button>
									</Grid>

									<Grid item xs={2} md={2} lg={2} />

									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											style={{
												backgroundColor: '#0C8D19',
												justifyContent: 'center',
												alignItems: 'center',
												padding: '6px 6px 6px 8px',
												width: '100%',
												height: '45px',
												borderRadius: '4px'
											}}
											onClick={handleSubmit}
										>
											Simpan
										</Button>
									</Grid>
								</Grid>
							</Container>
						</Container>
					</Paper>
				</Container>
			</Modal>
		</ThemeProvider>
	);
}
