export const AUTH = 'AUTH';
export const CREATE = 'CREATE';
export const LOGOUT = 'LOGOUT';
export const FETCH_PORT = 'FETCH_PORT';
export const FETCH_ALL = 'FETCH_ALL';
export const GET_TIPE_KENDARAAN_SUCCESS = 'GET_TIPE_KENDARAAN_SUCCESS';
export const GET_WEIGH_TOTAL_SUCCESS = 'GET_WEIGH_TOTAL_SUCCESS';
export const GET_DATA_DRIVER_SUCCESS = 'GET_DATA_DRIVER_SUCCESS';
export const GET_DATA_MUATAN_SUCCESS = 'GET_DATA_MUATAN_SUCCESS';
export const GET_DATA_JENIS_MUATAN_SUCCESS = 'GET_DATA_JENIS_MUATAN_SUCCESS';
export const GET_DATA_SITE_SUCCESS = 'GET_DATA_SITE_SUCCESS';
export const GET_DATA_SCANNER_SUCCESS = 'GET_DATA_SCANNER_SUCCESS';
export const GET_DATA_ASSIGNMENT_SUCCESS = 'GET_DATA_ASSIGNMENT_SUCCESS';
export const GET_ALL_ASSIGNMENT_SUCCESS = 'GET_ALL_DATA_SUCCESS';
export const GET_DATA_USERS_SUCCESS = 'GET_DATA_USERS_SUCCESS';
export const GET_DATA_USERS_BYID_SUCCESS = 'GET_DATA_USERS_BYID_SUCCESS';
export const GET_DATA_ROLE_SUCCESS = 'GET_DATA_ROLE_SUCCESS';
export const GET_DATA_LOG_SUCCESS = 'GET_DATA_LOG_SUCCESS';
export const GET_DATA_LOG_BY_DATE = 'GET_DATA_LOG_BY_DATE';
export const GET_DATA_LOG_SOS_SUCCESS = 'GET_DATA_LOG_SOS_SUCCESS';
export const GET_DATA_SHIFT_SUCCESS = 'GET_DATA_SHIFT_SUCCESS';
export const GET_DATA_KENDARAAN_SUCCESS = 'GET_DATA_KENDARAAN_SUCCESS';
export const GET_DATA_REPORT_SUCCESS = 'GET_DATA_REPORT_SUCCESS';
export const GET_DATA_REPORT_BY_DATE = 'GET_DATA_REPORT_BY_DATE';
export const GET_DATA_REPORT_BY_SITE = 'GET_DATA_REPORT_BY_SITE';
export const GET_DATA_REPORT_BY_SCANNER = 'GET_DATA_REPORT_BY_SCANNER';
export const GET_DATA_REPORT_BY_VIN = 'GET_DATA_REPORT_BY_VIN';
export const GET_TOTAL_HD_SUCCESS = 'GET_TOTAL_HD_SUCCESS';
export const GET_TOTAL_MC_SUCCESS = 'GET_TOTAL_MC_SUCCESS';
export const GET_TOTAL_MD_SUCCESS = 'GET_TOTAL_MD_SUCCESS';
export const GET_TOTAL_MEDIUM_SUCCESS = 'GET_TOTAL_MEDIUM_SUCCESS';
export const GET_TOP_TRUCK_SUCCESS = 'GET_TOP_TRUCK_SUCCESS';
export const GET_TOP_MUATAN_SUCCESS = 'GET_TOP_MUATAN_SUCCESS';
export const GET_URL_SUCCESS = 'GET_URL_SUCCESS';
export const GET_DOWNLOAD_REPORT_SUCCESS = 'GET_DOWNLOAD_REPORT_SUCCESS';
export const ADD_DRIVER_SUCCESS = 'ADD_DRIVER_SUCCESS';
export const ADD_VEHICLE_SUCCESS = 'ADD_VEHICLE_SUCCESS';
export const ADD_CITY_SUCCESS = 'ADD_CITY_SUCCESS';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_SITE_SUCCESS = 'ADD_SITE_SUCCESS';
export const ADD_SCANNER_SUCCESS = 'ADD_SITE_SUCCESS';
export const ADD_VEHICLE_TYPE_SUCCESS = 'ADD_VEHICLE_TYPE_SUCCESS';
export const ADD_ASSIGNMENT_SUCCESS = 'ADD_ASSIGNMENT_SUCCESS';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_SHIFT_SUCCESS = 'ADD_SHIFT_SUCCESS';
export const ADD_PORT_SUCCESS = 'ADD_PORT_SUCCESS';
export const DELETE_DRIVER_SUCCESS = 'DELETE_DRIVER_SUCCESS';
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';
export const DELETE_SHIFT_SUCCESS = 'DELETE_SITE_SUCCESS';
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const DELETE_SCANNER_SUCCESS = 'DELETE_SCANNER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_ASSIGNMENT_SUCCESS = 'DELETE_ASSIGNMENT_SUCCESS';
