import { GET_DATA_MUATAN_SUCCESS, ADD_CITY_SUCCESS, DELETE_CITY_SUCCESS } from '../constants/actionTypes';

const initialState = {
	city: [],
	loading: true,
	error: ''
};

export default (dataMuatan = initialState, action) => {
	switch (action.type) {
		case GET_DATA_MUATAN_SUCCESS:
			return {
				...dataMuatan,
				loading: false,
				city: action.payload
			};
		case ADD_CITY_SUCCESS:
	    dataMuatan['loading'] = false;
	    let dataTemp = dataMuatan['city']
	    dataTemp = [...dataTemp, action.payload];
	    dataMuatan['city'] = dataTemp;
	   return dataMuatan;
		case DELETE_CITY_SUCCESS:
				 return {
					 ...dataMuatan,
					 loading: false,
					 city: action.payload
				 };
		default:
			return dataMuatan;
	}
};
