import {
	GET_DATA_KENDARAAN_SUCCESS,
	GET_TIPE_KENDARAAN_SUCCESS,
	GET_WEIGH_TOTAL_SUCCESS,
	GET_TOTAL_HD_SUCCESS,
 	GET_TOTAL_MC_SUCCESS,
	GET_TOTAL_MD_SUCCESS,
	GET_TOTAL_MEDIUM_SUCCESS,
	GET_TOP_TRUCK_SUCCESS,
	GET_TOP_MUATAN_SUCCESS,
 	} from '../constants/actionTypes';
import * as api from '../api';

export const getListVehicle = (auth) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getListVehicle(auth);
		console.log(data);
		const dataKendaraan = data.result;
		dispatch({ type: GET_DATA_KENDARAAN_SUCCESS, payload: dataKendaraan });
	} catch (error) {
		console.log(error);
	}
};

export const getVehicleType = (auth) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getVehicleType(auth);
		console.log(data);
		const tipeKendaraan = data.result;
		dispatch({ type: GET_TIPE_KENDARAAN_SUCCESS, payload: tipeKendaraan });
	} catch (error) {
		console.log(error);
	}
};

export const getLastLogWeighTotal = (auth) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getLastLogWeighTotal(auth);
		console.log(data);
		const dataKendaraan = data.result;
		dispatch({ type: GET_WEIGH_TOTAL_SUCCESS, payload: dataKendaraan });
	} catch (error) {
		console.log(error);
	}
};

export const getTotalHighDirty = (auth, date, type) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getTotalHighDirty(auth, date, type);
		console.log(data.result);
		const total = data.result;
		dispatch({ type: GET_TOTAL_HD_SUCCESS, payload: total });
	} catch (error) {
		console.log(error);
	}
};

export const getTotalMediumClean = (auth, date, type) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getTotalMediumClean(auth, date, type);
		console.log(data);
		const total = data.result;
		dispatch({ type: GET_TOTAL_MC_SUCCESS, payload: total });
	} catch (error) {
		console.log(error);
	}
};

export const getTotalMediumDirty = (auth, date, type) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getTotalMediumDirty(auth, date, type);
		console.log(data);
		const total = data.result;
		dispatch({ type: GET_TOTAL_MD_SUCCESS, payload: total });
	} catch (error) {
		console.log(error);
	}
};

export const getTotalMedium = (auth, date, type) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getTotalMedium(auth, date, type);
		console.log(data);
		const total = data.result;
		dispatch({ type: GET_TOTAL_MEDIUM_SUCCESS, payload: total });
	} catch (error) {
		console.log(error);
	}
};

export const getTopTruck = (auth, date, type) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getTopTruck(auth, date, type);
		console.log(data);
		const total = data.result;
		dispatch({ type: GET_TOP_TRUCK_SUCCESS, payload: total });
	} catch (error) {
		console.log(error);
	}
};

export const getTopMuatan = (auth, date, type) => async (dispatch) => {
	try {
		// console.log('hai');
		const { data } = await api.getTopMuatan(auth, date, type);
		console.log(data);
		const total = data.result;
		dispatch({ type: GET_TOP_MUATAN_SUCCESS, payload: total });
	} catch (error) {
		console.log(error);
	}
};
