import { combineReducers } from 'redux';

import auth from './auth';
import dataDriver from './dataDriver';
import dataKendaraan from './dataKendaraan';
import dataMuatan from './dataMuatan';
import dataCategory from './dataCategory';
import dataSite from './dataSite';
import dataScanner from './dataScanner';
import dataAssignment from './dataAssignment';
import dataUser from './dataUser';
import dataLog from './dataLog';
import dataShift from './dataShift';
import dataLogSOS from './dataLogSOS';
import dataReport from './dataReport';
import dataPort from './dataPort';

export const reducers = combineReducers({
	auth,
	dataDriver,
	dataKendaraan,
	dataMuatan,
	dataCategory,
	dataSite,
	dataScanner,
	dataAssignment,
	dataUser,
	dataLog,
	dataShift,
	dataLogSOS,
	dataReport,
	dataPort
});
