import axios from 'axios';
require('dotenv').config();

export const getListUsers = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/users`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListDriver = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/driver`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListVehicle = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListCity = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/city`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListCategory = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/category`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListSite = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/site`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getDataUserById = (auth, user_id) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/users/profile?user_id=${user_id}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListScanner = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/scanner`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListAssignment = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/assigment`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListLog = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/users/log`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListLogByDate = (auth, startDate, endDate) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/users/log?start=${startDate}&end=${endDate}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListLogSOS = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/users/log_sos`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListShift = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/shift`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getVehicleType = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/type`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getAllAssignment = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/assigment/all`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getPrintAssignment = (auth, id) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/download/card/?assignment_id=${id}`, {
		responseType: 'blob',
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListRole = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/users/role`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getListReport = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/report`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getLastLogWeighTotal = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/last_log`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getTotalHighDirty = (auth, date, type) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/hd?date=${date}&type=${type}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getTotalMediumClean = (auth, date, type) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/mc?date=${date}&type=${type}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getTotalMediumDirty = (auth, date, type) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/md?date=${date}&type=${type}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getTotalMedium = (auth, date, type) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/total?date=${date}&type=${type}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getTopTruck = (auth, date, type) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/top_truck?date=${date}&type=${type}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getTopMuatan = (auth, date, type) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/top_cat?date=${date}&type=${type}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getReportByDate = (auth, startDate, endDate) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/report?start=${startDate}&end=${endDate}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getReportBySite = (auth, site) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/report?site=${site}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getReportByScanner = (auth, scanner) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/report?scanner=${scanner}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});

export const getReportByVin = (auth, vin) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/report?vin=${vin}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getUrl = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/setting/url`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getDownloadReportByDate = (auth, user_id, start, end) =>
	axios.get(
		`${process.env
			.REACT_APP_MY_ENVIRONMENT_VARIABLE}/download/report/?user_id=${user_id}&start=${start}&end=${end}`,
		{
			responseType: 'blob',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
				token: auth
			},
			withCredentials: false
		}
	);
export const getDownloadReport = (auth, user_id) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/download/report/?user_id=${user_id}`, {
		responseType: 'blob',
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
