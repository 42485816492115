import { GET_DATA_DRIVER_SUCCESS } from '../constants/actionTypes';
import * as api from '../api';

export const getListDriver = (auth) => async (dispatch) => {
  try{
    console.log('hai');
    const { data } = await api.getListDriver(auth);
    console.log(data);
    const dataDriver = data.result;
    dispatch({ type: GET_DATA_DRIVER_SUCCESS, payload: dataDriver })
  } catch (error) {
    console.log(error)
  }
}
