import { GET_DATA_LOG_SOS_SUCCESS } from '../constants/actionTypes';

const initialState = {
	log: [],
	loading: true,
	error: ''
};

export default (dataLogSOS = initialState, action) => {
	switch (action.type) {
		case GET_DATA_LOG_SOS_SUCCESS:
			return {
				...dataLogSOS,
				loading: false,
				log: action.payload
			};
		default:
			return dataLogSOS;
	}
};
