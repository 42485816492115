import { makeStyles } from  '@mui/styles';

export default makeStyles((theme) => ({
  container:{
    maxWidth:'100vw',
  },
  boxContainer:{
    maxWidth: '90vw',
    height: '250px',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.14)',
    borderRadius: '5px',
    background:'#FFFFFF',
  },
  text:{
      fontFamily: 'Noto Sans sans-serif',
      fontStyle: 'italic',
      fontSize: '16px',
      color: '#E64A19',
  },
  input:{
    width:'390px',
    height: '56px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #C4C4C4',
    boxSizing: 'borderBox',
    borderRadius: '3px',
  },
  box:{
    width:'110px',
    height: '56px',
    background: '#C4C4C4',
    border: '1px solid #C4C4C4',
    boxSizing: 'borderBox',
    borderRadius: '0px 3px 3px 0px',
  },
  btn: {
    width:'430px',
    height: '45px',
    background: '#0C8D19',
    borderRadius:'4px',
    alignItems: 'center',
    padding: '6px 6px 6px 8px'
  }
}));
