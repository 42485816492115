import { GET_DATA_ASSIGNMENT_SUCCESS, GET_ALL_ASSIGNMENT_SUCCESS, ADD_ASSIGNMENT_SUCCESS, DELETE_ASSIGNMENT_SUCCESS } from '../constants/actionTypes';

const initialState = {
	assignment: [],
	dataVin: [],
	dataCat: [],
	dataCity: [],
	loading: true,
	error: ''
};

export default (dataAssignment = initialState, action) => {
	switch (action.type) {
		case GET_DATA_ASSIGNMENT_SUCCESS:
			return {
				...dataAssignment,
				loading: false,
				assignment: action.payload
			};
			case GET_ALL_ASSIGNMENT_SUCCESS:
				return {
					...dataAssignment,
					loading: false,
					dataVin: action.payload.dataVin,
					dataCat: action.payload.dataCat,
					dataCity: action.payload.dataCity,
				};
				case ADD_ASSIGNMENT_SUCCESS:
		      dataAssignment['loading'] = false;
		      let dataTemp = dataAssignment['assignment']
		      dataTemp = [...dataTemp, action.payload];
		      dataAssignment['assignment'] = dataTemp;
		    return dataAssignment;
				case DELETE_ASSIGNMENT_SUCCESS:
						 return {
							 ...dataAssignment,
							 loading: false,
							 assignment: action.payload
						 };
		default:
			return dataAssignment;
	}
};
