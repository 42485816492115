import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useStyles from './styles';
import { getListShift } from '../../../actions/shift';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
require('dotenv').config();

const theme = createTheme();

export default function DataForm({ dataForm, shiftid, shift_code, shift_name, shift_start, shift_end, shift_desc }) {
	const [ shiftcode, setShiftCode ] = useState(shift_code);
	const [ shiftname, setShiftName ] = useState(shift_name);
	const [ shiftstart, setShiftStart ] = useState(shift_start);
	const [ shiftend, setShiftEnd ] = useState(shift_end);
	const [ shiftdesc, setShiftDesc ] = useState(shift_desc);
	const [ open, setOpen ] = useState(false);
	const [ timeStart, setTimeStart ] = useState(null);
	const [ timeEnd, setTimeEnd ] = useState(null);
	const data = useSelector((state) => state.data);
	const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));
	const [ dataValid, setDataValid ] = useState(true);

	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (shiftcode !== '' && shiftname !== '' && shiftstart !== '' && shiftend !== '' && shiftdesc !== '') {
			const resp = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/shift/update`,
				withCredentials: false,
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
					'Content-Type': 'application/json',
					Accept: '*/*',
					token: auth
				},
				data: {
					shift_id: shiftid,
					shift_code: shiftcode,
					shift_name: shiftname,
					shift_start: shiftstart,
					shift_end: shiftend,
					shift_desc: shiftdesc
				}
			});

			const json = await resp;
			console.log(json);

			if (json.data) {
				dispatch(getListShift(auth));
				setOpen(false);
				setDataValid(true);
			} else {
				console.log('error');
			}
		} else {
			setDataValid(false);
		}
	};

	const handleOpen = (id) => {
		if (id !== null) {
			setOpen(true);
		}
	};

	const handleClose = () => {
		setOpen(false);
		setDataValid(true);
	};

	return (
		<ThemeProvider theme={theme}>
			<EditIcon sx={{ color: '#2196F3' }} onClick={() => handleOpen(shiftid)} />
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.shiftFormContainer}
			>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<Paper className={classes.paper} sx={{ mt: '7vh' }} style={{ borderRadius: '10px' }}>
						<Box className={classes.box}>
							<Typography
								className={classes.text}
								style={{ fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '18px' }}
								sx={{ ml: '2%', mt: '1%' }}
								component="h3"
								variant="h8"
							>
								FORM SHIFT
							</Typography>
						</Box>
						<Container component="form" className={classes.form} noValidate sx={{ mt: 4 }}>
							{!dataValid ? (
								<div>
									<Alert severity="error" sx={{ mt: 3, mb: 2 }}>
										Lengkapi Semua Data (*)
									</Alert>
								</div>
							) : null}
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										type="text"
										value={shiftcode}
										name="kodeShift"
										onChange={(e) => setShiftCode(e.target.value)}
										label="Kode Shift"
										autoComplete="kodeShift"
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										multiline
										type="text"
										value={shiftname}
										name="nama"
										onChange={(e) => setShiftName(e.target.value)}
										label="Nama"
										autoComplete="nama"
									/>
								</Grid>
								<Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<TimePicker
											label="Time"
											value={shiftstart}
											onChange={(newValue) => {
												setShiftStart(newValue);
											}}
											renderInput={(params) => (
												<TextField {...params} style={{ fontSize: '10px' }} />
											)}
										/>
									</LocalizationProvider>
									<Typography
										className={classes.text}
										style={{ fontWeight: 'bold', color: '#455A64' }}
										sx={{ ml: 2, mr: 2, mt: 2 }}
									>
										{' '}
										s/d{' '}
									</Typography>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<TimePicker
											label="Time"
											value={shiftend}
											onChange={(newValue) => {
												setShiftEnd(newValue);
											}}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</Grid>
								<Typography className={classes.helperText} style={{ fontSize: '10px' }} sx={{ ml: 8 }}>
									Masukkan range waktu shift kerja, contoh : 08.00 s/d 16.00
								</Typography>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%' }}
										required
										multiline
										rows={5}
										type="text"
										value={shiftdesc}
										name="keterangan"
										onChange={(e) => setShiftDesc(e.target.value)}
										label="Keterangan"
										autoComplete="keterangan"
									/>
								</Grid>
							</Grid>

							<Container style={{ padding: '20px' }}>
								{/* <Container > */}
								<Grid container style={{ display: 'flex', flexDirection: 'row' }}>
									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											fullWidth
											variant="outlined"
											color="error"
											sx={{
												justifyContent: 'center',
												alignItems: 'center',
												width: '128px',
												height: '45px',
												borderRadius: '4px'
											}}
											onClick={handleClose}
										>
											Batal
										</Button>
									</Grid>

									<Grid item xs={2} md={2} lg={2} />

									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											style={{
												backgroundColor: '#0C8D19',
												justifyContent: 'center',
												alignItems: 'center',
												padding: '6px 6px 6px 8px',
												width: '100%',
												height: '45px',
												borderRadius: '4px'
											}}
											onClick={handleSubmit}
										>
											Simpan
										</Button>
									</Grid>
								</Grid>
							</Container>
						</Container>
					</Paper>
				</Container>
			</Modal>
		</ThemeProvider>
	);
}
